//@ts-check

import { makeAutoObservable } from 'mobx';

import Firebase from '../firebase';

class GlobalStore {

    firebase = null; // Objeto de conexión de firebase
    formik = null; // variable para controlar cambios en formularios
    user = null; // Objero Usuario
    userType = null; // Tipo de usuario
    countryId = null; // Identificador de pais
    countryName = null; // Nombre de pais
    compId = null; // Identificador de empresa
    compName = null; // Nombre de empresa
    fincaId = null; // Identificador de finca
    globalLoading = true; // Variable de control de primera carga

    constructor() {
        this.firebase = new Firebase();
        this.defUser();
        makeAutoObservable(this);
    }

    resetStore = () => {
        this.user = null;
        this.userType = null;
        this.countryId = null;
        this.countryName = null;
        this.compId = null;
        this.compName = null;
        this.fincaId = null;
        this.formik = null;
    };

    defUser = () => {
        this.firebase.auth.onAuthStateChanged((user) => {
            if (user) {
                this.setUser(this.firebase.auth.currentUser);
            } else {
                this.setUser(null);
            }
            this.setGlobalLoading(false);
        });
    };

    setUser = (usr) => {
        this.user = usr;
    };

    setUserType = (usrType) => {
        this.userType = usrType;
    };

    setCountryId = (id) => {
        this.countryId = id;
    };

    setCountryName = (name) => {
        this.countryName = name;
    };

    setCompId = (id) => {
        this.compId = id;
    };

    setCompName = (name) => {
        this.compName = name;
    };

    setFincaId = (id) => {
        this.fincaId = id;
    };

    setGlobalLoading = (bool) => {
        this.globalLoading = bool;
    };

    setFormik = (formik) => {
        this.formik = formik;
    };
}

const globalStore = new GlobalStore();

export default globalStore;

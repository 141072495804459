////@ts-check
import React from "react";
import { Message } from "primereact/message";
import _ from "lodash";

/**
 *
 * @param {*} param0
 * @returns
 */
export default function Err({ mutationError, calcError, error }) {
    if (mutationError) {
        const { graphQLErrors } = mutationError;
        const errors = _.map(graphQLErrors, ({ message }, i) => (
            <Message severity="warn" text={message} key={i} />
        ));
        return <div className="err-server">{errors}</div>;
    }
    if (calcError) {
        const { networkError } = calcError;
        const errorsArr = _.reduce(networkError, (acc, e) => e.errors, []);
        const errors = _.map(errorsArr, ({ message }, i) => (
            <Message severity="error" text={message} key={i} />
        ));
        return <div className="err-calc">{errors}</div>;
    }
    if (error) {
        const { graphQLErrors } = error;
        const errors = _.map(graphQLErrors, ({ message }, i) => (
            <Message severity="error" text={message} key={i} />
        ));
        if (!_.isEmpty(errors)) {
            return <div className="err-server">{errors}</div>;
        }
        return (
            <div className="err-server">
                <Message severity="error" text={error.message} />
            </div>
        );
    }
    return null;
}

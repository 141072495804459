import { gql } from '@apollo/client';

const MANTENIMIENTO_CALC = gql`
  query CalcMantenimiento($producto: String!, $consumo: Float!, $empresa: ID!) {
    calculoMantenimiento(
      producto: $producto
      consumo: $consumo
      empresa: $empresa
    )
  }
`;

export { MANTENIMIENTO_CALC };

////@ts-check

import React, { useContext } from 'react';
import { MobXProviderContext } from "mobx-react";
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


import { EMPRESA_DEF, EMPRESA_ADD, EMPRESAS_LIST } from '../../../queries/queries_empresa';
import * as ROUTES from '../../../constants/routes';
import { CompLoading } from '../../Loaders';
import Err from '../../Err';

import FieldBuilder from '../../../helpers/FieldBuilder';
import validationSchema from '../../../helpers/validationSchema';
import initialValues from '../../../helpers/initialValues';


export default  function AddEmpresa() {
    const { GlobalStore } = useContext(MobXProviderContext);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { loading, error, data } = useQuery(EMPRESA_DEF);

    const [
        addEmpresa,
        { loading: mutationLoading, error: mutationError },
    ] = useMutation(EMPRESA_ADD);

    if (GlobalStore.userType !== 1) {
        navigate(ROUTES.DASHBOARD);
    }

    if (loading) {
        return <CompLoading />;
    }

    if (error) {
        return <Err error={error} />;
    }

    const def = JSON.parse(data.definicion);
    const { uid } = GlobalStore.user;

    return (
        <React.Fragment>
            <div className="breadcrum">
                <Link to={ROUTES.READ_EMPRESAS}>
                    <i className="pi pi-list" /> &nbsp; {t('crud.registered_companies')}
                </Link>
            </div>
            <h2>{t('crud.create_company')}</h2>
            {mutationLoading && <CompLoading />}
            <Err mutationError={mutationError} />
            <Formik
                initialValues={initialValues(def)}
                validationSchema={validationSchema(def)}
                enableReinitialize={true}
                onSubmit={async (values) => {
                    await addEmpresa({
                        variables: {
                            ...values,
                            pais: GlobalStore.countryId,
                            uid,
                        },
                        refetchQueries: [
                            {
                                query: EMPRESAS_LIST,
                                variables: {
                                    pais: GlobalStore.countryId,
                                },
                            },
                        ],
                    });
                    navigate(ROUTES.READ_EMPRESAS);
                }}
            >
                {(formik) => {
                    GlobalStore.setFormik(formik);
                    return (
                        <form autoComplete="off" onSubmit={formik.handleSubmit}>
                            <div className="p-inputgroup">
                                <FieldBuilder def={def} />
                            </div>
                            <div className="btn-actions">
                                <Button
                                    type="submit"
                                    label={t('common:save')}
                                    icon="pi pi-check"
                                    disabled={mutationLoading}
                                />
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </React.Fragment>
    );
}


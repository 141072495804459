//@ts-check

import React from 'react';
import _ from 'lodash';


import Text from './Text';
import Email from './Email';
import Password from './Password';
import Phone from './Phone';
import Number from './Number';
import NumberInt from './NumberInt';
import NumberUnits from './NumberUnits';
import Date from './Date';
import Select from './Select';
import SelectGroup from './SelectGroup';
import SelectYear from './SelectYear';
import Conditional from './Conditional';
import MapGooglePoint from './MapGooglePoint';
import TextUpload from './TextUpload';

export default function FieldBuilder({ def }) {
    // console.log('def', def, '@FieldBuilder');
    const handleFieldsPerType = () => {
        return _.map(def, (field) => {
            const { type, fieldname, required, disabled } = field;
            switch (type) {
                case 'text':
                    return <Text field={field} key={fieldname} required={required} disabled={disabled} />;
                case 'password':
                    return <Password field={field} key={fieldname} required={required} disabled={disabled} />;
                case 'email':
                    return <Email field={field} key={fieldname} required={required} disabled={disabled} />;
                case 'phone':
                    return <Phone field={field} key={fieldname} required={required} disabled={disabled} />;
                case 'select':
                    return <Select field={field} key={fieldname} required={required} disabled={disabled} />;
                case 'selectGroup':
                    return <SelectGroup field={field} key={fieldname} required={required} disabled={disabled} />;
                case 'selectYear':
                    return <SelectYear field={field} key={fieldname} required={required} disabled={disabled} />;
                case 'number':
                    return <Number field={field} key={fieldname} required={required} disabled={disabled} />;
                case 'numberInt':
                    return <NumberInt field={field} key={fieldname} required={required} disabled={disabled} />;
                case 'numberUnits':
                    return <NumberUnits field={field} key={fieldname} required={required} disabled={disabled} />;
                case 'date':
                    return <Date field={field} key={fieldname} required={required} disabled={disabled} />;
                case 'conditional':
                    return <Conditional field={field} key={fieldname} required={required} disabled={disabled} />;
                case 'coordinates':
                    return <MapGooglePoint field={field} key={fieldname} required={required} disabled={disabled} />;
                case 'textUpload':
                    return <TextUpload field={field} key={fieldname} required={required} disabled={disabled} />;
                default:
                    return null;
            }
        });
    };

    return (
        <div className='form-fields'>
            <div className='input-fields'>{handleFieldsPerType()}</div>
        </div>
    );
}

////@ts-check

import React, { useContext } from 'react';
import { MobXProviderContext } from "mobx-react";
import { Link } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


import { FINCA_DEF, FINCA_ADD, FINCAS_LIST } from '../../../queries/queries_finca';
import * as ROUTES from '../../../constants/routes';
import { CompLoading } from '../../Loaders';
import Err from '../../Err';

import FieldBuilder from '../../../helpers/FieldBuilder';
import validationSchema from '../../../helpers/validationSchema';
import initialValues from '../../../helpers/initialValues';


export default function AddFinca() {
    const { GlobalStore } = useContext(MobXProviderContext);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { uid } = GlobalStore.user;

    const { loading, error, data } = useQuery(FINCA_DEF);

    const [ addFinca, { loading: mutationLoading, error: mutationError } ] = useMutation(FINCA_ADD);

    if (GlobalStore.userType !== 2) {
        navigate(ROUTES.DASHBOARD);
    }

    if (loading) {
        return <CompLoading />;
    }

    if (error) {
        return <Err error={error} />;
    }

    const def = JSON.parse(data.definicion);

    return (
        <React.Fragment>
            <div className="breadcrum">
                <Link to={ROUTES.READ_FINCAS}>
                    <i className="pi pi-list" /> &nbsp; {t('crud.registered_farms')}
                </Link>
            </div>
            <h2>{t('crud.create_farm')}</h2>
            {mutationLoading && <CompLoading />}
            <Err mutationError={mutationError} />
            <Formik
                initialValues={initialValues(def)}
                validationSchema={validationSchema(def)}
                enableReinitialize={true}
                onSubmit={async (values) => {
                    await addFinca({
                        variables: {
                            ...values,
                            uid,
                        },
                        refetchQueries: [
                            {
                                query: FINCAS_LIST,
                                variables: {
                                    uid,
                                },
                            },
                        ],
                    });
                    navigate(ROUTES.READ_FINCAS);
                }}
            >
                {(formik) => {
                    GlobalStore.setFormik(formik);
                    return (
                        <form autoComplete="off" onSubmit={formik.handleSubmit}>
                            <div>
                                <FieldBuilder def={def} />
                            </div>
                            <div className="btn-actions">
                                <Button
                                    type="submit"
                                    label={t('common:save')}
                                    icon="pi pi-check"
                                    disabled={mutationLoading}
                                />
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </React.Fragment>
    );
}

//@ts-check

import _ from 'lodash';
import i18n from "i18next";

const isExponential = /[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)/;

const decimales = 2;

export function formatTxt(v, num) {
    const opts = (num !== undefined) ? {
        style: 'decimal',
        minimumFractionDigits: ((num!==undefined)&&(num!==null)) ? num : decimales,
        maximumFractionDigits: ((num!==undefined)&&(num!==null)) ? num : decimales
    } : {
        notation: 'scientific',
        roundingPriority: 'morePrecision',
        maximumFractionDigits: 20
    };
    // @ts-ignore
    const r = new Intl.NumberFormat(i18n.language.split('-')[0], opts ).format(v);
    return (r==='-0')?'0':r;
}

export function objectRound(obj) {
    return _.reduce(
        obj,
        (acc, value, key) => {
            if (_.isNumber(value) && !isExponential.test(''+value)) {
                return {
                    ...acc,
                    [key]: formatTxt(value)
                };
            }
            return {
                ...acc,
                [key]: value
            };
        },
        {}
    );
}

export function objectRoundWithoutZeros(obj, preciso) {
    return _.reduce(
        obj,
        (acc, value, key) => {
            const decim = (preciso && (value < 0.01) && (value > -0.01)) ? preciso : undefined;
            return {
                ...acc,
                [key]: (_.isNumber(value)) ? numberFormatTxt(value, decim) : value
            };
        },
        {}
    );
}

export function rounder(arr, preciso) {
    return _.map(arr, (obj) => objectRoundWithoutZeros(obj, preciso));
}

export function singleRounder(val, num) {
    if (isExponential.test(val)) return val;
    return _.round(val, ((num!==undefined)&&(num!==null)) ? num : decimales);
}

export function precision(a) {
    if (!isFinite(a)) return 0;
    var e = 1, p = 0;
    while (Math.round(a * e) / e !== a) { e *= 10; p++; }
    return p;
}

export function numberFormatTxt(val, num) {
    if (val === undefined || val === null || !_.isNumber(val)) return '';
    if (isExponential.test(String(val))) return formatTxt(val);
    // const nm = Number(Number(val).toFixed(num || decimales)).toString().split('.');
    // const decim = (nm.length>1) ? nm[1].length: 0;
    const decim = Math.min((num || decimales), precision(val), 20);
    // console.log(val, num, ' => ',  nm, decim);
    return formatTxt(val, decim);

}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat

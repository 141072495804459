import React from 'react';
import { inject, observer } from 'mobx-react';
// import { MobXProviderContext } from "mobx-react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";


import * as ROUTES from "../../constants/routes";
import LanguageSelector from "../../helpers/languageSelector/languageSelector";

import "./top-menu.scss";


function TopMenu({GlobalStore}) {
    // const { GlobalStore } = useContext(MobXProviderContext);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const {
        firebase: { logout },
        userType,
        fincaId,
        compName,
        countryName
    } = GlobalStore;


    const handleLogout = async () => {
        GlobalStore.resetStore();
        await logout();
        return navigate(ROUTES.DASHBOARD);
    };

    const handleShowFinca = () => {
        if (userType === 1) {
            return (
                <li className="finca selected">
                    <i className="pi pi-check-circle" />
                    {countryName}
                </li>
            );
        }
        if (userType === 2) {
            return (
                <li className="finca selected">
                    <i className="pi pi-check-circle" />
                    {compName}
                </li>
            );
        }
        if (userType !== 3) return null;
        if (_.isEmpty(fincaId)) {
            return (
                <li className="finca empty">
                    <i className="pi pi-info-circle" />
                    { t('error:no_selected_farm') }
                </li>
            );
        }
        return (
            <li className="finca selected">
                <i className="pi pi-check-circle" />
                {fincaId.label}
            </li>
        );
    };

    return (
        <div className="top-menu">
            <ul>
                {handleShowFinca()}
                <li>
                    <LanguageSelector/>
                </li>
                <li>
                    <Button
                        type="button"
                        label={t('common:exit')}
                        icon="pi pi-sign-out"
                        iconPos="right"
                        onClick={handleLogout}
                    />
                </li>
            </ul>
        </div>
    );
}

export default inject('GlobalStore')(observer(TopMenu));

//@ts-check

import React, { useContext } from 'react';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { MobXProviderContext } from 'mobx-react';
import { useMutation } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


import { FORM_DELETE } from '../../../queries/queries_forms';
import * as ROUTES from '../../../constants/routes';
import { CompLoading } from '../../Loaders';
import Err from '../../Err';

import './delete.scss';

export default function DeleteForm() {
    const { GlobalStore } = useContext(MobXProviderContext);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { form, id } = useParams();

    const [deteleForm, { loading, error }] = useMutation(FORM_DELETE, {
        variables: {
            id,
            uid: GlobalStore.user.uid,
        }
    });

    if (loading) {
        return <CompLoading />;
    }

    if (error) {
        return <Err error={error} />;
    }

    return (
        <div className="page-delete-form">
            <h1> {t('crud.delete_form')} </h1>
            <Message severity="warn" text={t('crud.please_confirm')}></Message>
            <p>
                {t('crud.permanent_deletion')}
            </p>
            <div className="actions">
                <Button
                    type="button"
                    label={t('common:cancel')}
                    className="p-button-primary"
                    onClick={() => navigate(`${ROUTES.READ_FORMS}/${form}`)}
                />
                <Button
                    type="button"
                    label={t('common:delete')}
                    className="p-button-danger"
                    onClick={async () => {
                        await deteleForm();
                        navigate(`${ROUTES.READ_FORMS}/${form}`);
                    }}
                />
            </div>
        </div>
    );
}

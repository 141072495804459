import { gql } from '@apollo/client';

const PAIS_DEF = gql`
  {
    definicion(tipoDefinicion: "paisCrear")
  }
`;

const PAIS_GET = gql`
  query PaisGet($id: ID!) {
    pais(id: $id) {
      id
      nombrePais
      codigoPais
      publicado
    }
    definicion(tipoDefinicion: "paisActualizar")
  }
`;

const PAIS_ADD = gql`
  mutation PaisAdd($uid: ID!, $nombrePais: String!, $codigoPais: String!) {
    paisCrear(uid: $uid, nombrePais: $nombrePais, codigoPais: $codigoPais) {
      id
    }
  }
`;

const PAIS_EDIT = gql`
  mutation EditPais(
    $id: ID!
    $uid: ID!
    $nombrePais: String!
    $codigoPais: String!
  ) {
    paisActualizar(
      id: $id
      uid: $uid
      nombrePais: $nombrePais
      codigoPais: $codigoPais
    ) {
      id
    }
  }
`;

const PAISES_LIST = gql`
  query PaisesList {
    paises {
      id
      nombrePais
      codigoPais
      creadoPor {
        id
        displayName
      }
      fechaCreacion
      publicado
    }
  }
`;

export { PAIS_DEF, PAIS_GET, PAIS_ADD, PAIS_EDIT, PAISES_LIST };

////@ts-check

import React, { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import _ from 'lodash';
import { Dropdown } from 'primereact/dropdown';
import { useTranslation } from 'react-i18next';


import HandleLabel from './HandleLabel';
import Number from './Number';
import Select from './Select';
import Error from './Error';


export default function Conditional({ field, required, disabled }) {
    const idInput = '_' + Math.round(Math.random() * 10000000000);
    const { i18n, t } = useTranslation();
    const { GlobalStore: { formik } } = useContext(MobXProviderContext);
    const { fieldname, options, dependents } = field;
    const { handleChange, values, touched, errors, isValid } = formik;
    const traduce = (f, b) => {
        const r = b || f;
        return (i18n.exists('domains:' + fieldname + '.'+ r))
            ? t('domains:' + fieldname + '.'+ r)
            : r;
    };
    const handleOptions = (options) =>
        _.map(options, ({ fieldValue, label, fieldUnit }) => {

            //TODO: Eliminar testeo de diccionario
            // ========================== Para TESTEO ==========================
            // @ts-ignore
            // window.sacarEntradaDiccionario(fieldname, fieldValue, fieldText, 'helpers/FieldBuilder/Condicional');
            // ========================== Para TESTEO ==========================

            return {
                label: traduce(fieldValue, label),
                value: fieldValue,
                unit: fieldUnit
            };
        });
    const handleDependents = () => {
        if (values[fieldname] === null) return null;
        const dep = _.find(dependents, { fieldValue: values[fieldname] });
        if (_.isEmpty(dep)) return null;
        return _.map(dep.depFields, (field) => {
            const { type } = field;
            switch (type) {
                case 'select':
                    return <Select field={field} required={required} key={field.fieldname} disabled={disabled}/>;
                case 'number':
                    return <Number field={field} required={required} key={field.fieldname} disabled={disabled}/>;
                default:
                    return null;
            }
        });
    };
    const itemTemplate = (/** @type {any} */ option) => {
        return  option && (<div className="templateOptionSelect">
                    {option.label}
                    {option.unit && (<small>{i18n.exists('domains:units.'+option.unit)?t('domains:units.'+option.unit):option.unit}</small>)}
                </div>) || ('<div>&nbsp;</div>');
    };

    return (
        <React.Fragment>
            <div className="input-conditional">
                <label htmlFor={fieldname} className={`required-${required}`}>
                    <HandleLabel field={field} formik={formik}/>
                </label>
                <Dropdown
                    id={fieldname}
                    name={fieldname}
                    inputId={idInput}
                    options={handleOptions(options)}
                    onChange={handleChange}
                    value={values[fieldname]}
                    itemTemplate={itemTemplate}
                    valueTemplate={itemTemplate}
                    disabled={disabled}
                />
                <Error
                    isValid={isValid}
                    touched={touched[fieldname]}
                    errors={errors[fieldname]}
                />
            </div>
            {handleDependents()}
        </React.Fragment>
    );
}

import { gql } from '@apollo/client';

const RESULTS_VAR = gql`
  query ResultsVariables($uid: ID!, $empresa: ID!) {
    empresa(id: $empresa) {
        nombreEmpresa
    }
    configuraciones(uid: $uid) {
      id
      anio
    }
    fincas(uid: $uid) {
      id
      nombreFinca
    }
  }
`;

const REPORT_HUELLA_AGUA = gql`
  query ReportHuellaAgua(
    $configuracion: ID
    $pais: ID
    $empresa: ID
    $finca: ID
    $anio: String!
    $mes: String
    $fuente: String
  ) {
    reporteHuellaAgua(
      configuracion: $configuracion
      pais: $pais
      empresa: $empresa
      finca: $finca
      anio: $anio
      mes: $mes
      fuente: $fuente
    )
  }
`;
const REPORT_HUELLA_CARBONO = gql`
  query ReportHuellaCarbono(
    $configuracion: ID
    $pais: ID
    $empresa: ID
    $finca: ID
    $anio: String!
    $mes: String
    $alcance: String
    $formulario: String
    $formato: String
  ) {
    reporteHuellaCarbono(
      configuracion: $configuracion
      pais: $pais
      empresa: $empresa
      finca: $finca
      anio: $anio
      mes: $mes
      alcance: $alcance
      formulario: $formulario
      formato: $formato
    )
  }
`;

export { RESULTS_VAR, REPORT_HUELLA_AGUA, REPORT_HUELLA_CARBONO };

//@ts-check

import React, { useState, useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';

import { useTranslation } from 'react-i18next';


import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Message } from 'primereact/message';

export default function ResetPassword() {
    const { GlobalStore } = useContext(MobXProviderContext);
    const auth = GlobalStore.firebase.auth;
    const [email, setEmail] = useState(null);
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState(null);
    const [msg, setMsg] = useState(null);

    const [displayBasic, setDisplayBasic] = useState(false);

    const { t } = useTranslation();

    const dialogFuncMap = {
        displayBasic: setDisplayBasic,
    };

    const onClick = (/** @type {string} */ name) => {
        dialogFuncMap[`${name}`](true);
    };

    const onHide = (/** @type {string} */ name) => {
        dialogFuncMap[`${name}`](false);
    };

    const handleResetPassword = async () => {
        try {
            setLoading(true);
            await auth.sendPasswordResetEmail(email); // Send Reset Password
            setMsg(t('login.email_sent'));
            setErr(null);
            setLoading(false);
        } catch (error) {
            switch (error.code) {
                case 'auth/user-not-found':
                    error.message = t('error:auth_user_not_found');
                    break;

                case 'auth/invalid-email':
                    error.message = t('error:auth_invalid_email');
                    break;

                case 'auth/too-many-requests':
                    error.message = t('error:auth_too_many_requests');
                    break;

                default:
                    break;
            }
            setErr(error.message);
            setLoading(false);
        }
    };

    return (
        <div className="dialog-demo">
            <div className="card">
                <Button
                    type="button"
                    label={t('common:forgotten_password')}
                    className="p-button-link"
                    onClick={() => onClick('displayBasic')}
                />
                <Dialog
                    header={t('common:forgotten_password')}
                    visible={displayBasic}
                    style={{ width: '480px' }}
                    className="login-modal"
                    onHide={() => onHide('displayBasic')}>
                    <div className="login">
                        <p>{t('login.enter_email')}</p>
                        <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-user" />
                            </span>
                            <InputText
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder={t('common:email')}
                            />
                        </div>
                        <div className="p-inputgroup btn">
                            <Button
                                type="button"
                                onClick={() => handleResetPassword()}
                                label={t('common:recover_password')}
                                disabled={loading}
                            />
                        </div>
                        {err && <Message severity="error" text={err} />}
                        {msg && <Message severity="success" text={msg} />}
                    </div>
                </Dialog>
            </div>
        </div>
    );
}

////@ts-check

import React, { useContext, useEffect, useState } from 'react';
import { MobXProviderContext } from "mobx-react";
import { Button } from 'primereact/button';
import { useTranslation, Trans } from 'react-i18next';


import { AdminMenu, CountryMenu, CompMenu, OpMenu } from '../Menus';

import './sidebar.scss';


export default function Sidebar() {
    const { GlobalStore } = useContext(MobXProviderContext);
    const { userType } = GlobalStore;
    const [sidebar, setSidebar] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        let showSidebar = localStorage.getItem('showSidebar');
        if (!showSidebar) {
            localStorage.setItem('showSidebar', 'true');
            showSidebar = 'true';
        }
        setSidebar(JSON.parse(showSidebar) && true);
    }, []);

    const toggleSidebar = () => {
        const sb = !sidebar;
        setSidebar(sb);
        localStorage.setItem('showSidebar', JSON.stringify(sb));
    };

    const btn = () => {
        return (sidebar)
            ? (
                <Button
                    type="button"
                    onClick={() => toggleSidebar()}
                    icon="pi pi-angle-double-left"
                    className="p-button-rounded"
                    label={t('menu.contract')}
                />
            ) : (
                <Button
                    type="button"
                    onClick={() => toggleSidebar()}
                    icon="pi pi-angle-double-right"
                    className="p-button-rounded"
                />
            );
    };

    const handleMenuPerType = () => {
        switch (userType) {
            case 0:
                return <AdminMenu />;
            case 1:
                return <CountryMenu />;
            case 2:
                return <CompMenu />;
            case 3:
                return <OpMenu />;
            default:
                return null;
        }
    };

    return (
        <div className={sidebar ? 'sidebar expand' : 'sidebar collapse'}>
            <div className="toogle">{btn()}</div>
            <h1>
                <Trans i18nKey="menu.title_footprint"> </Trans>
            </h1>
            <div className="sidebar-menu">{handleMenuPerType()}</div>
            <div className="legend">
                {t('menu.subtitle_footprint_1')}
                <span> {t('menu.subtitle_footprint_2')}</span>
            </div>
        </div>
    );
}

// //@ts-check
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

//Endpointgit
import endpoint from './config/endpoint';

//Mobx
import { Provider as MobxProvider } from 'mobx-react';

//Apollo
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';

//PrimeReact
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';

//store
import GlobalStore from './store/global';
import './services/i18n';

//comps
import App from './components/App';

import { HelmetProvider } from 'react-helmet-async';

import './index.css';
import version from './version.json';

const client = new ApolloClient({
    uri: `${endpoint}/graphql`,
    cache: new InMemoryCache()
});

const v = `${version.buildMajor}.${version.buildMinor}.${version.buildRevision}`;
const vt = `Versión ${v} (${version.buildDate})`;
const vq = `(V. ${v})`;

ReactDOM.render(
    <Suspense fallback="_">
    <MobxProvider GlobalStore={GlobalStore} >
        <ApolloProvider client={client}>
            <div className="main-container">
                <HelmetProvider>
                    <React.StrictMode>
                        <App GlobalStore={GlobalStore} />
                    </React.StrictMode>
                </HelmetProvider>

                <div className="version" title={vt}>{vq}</div>

            </div>
        </ApolloProvider>
    </MobxProvider>
    </Suspense>,
    document.getElementById('root')
);

serviceWorker.unregister();

////@ts-check

import React, { useContext } from 'react';
import { observer, MobXProviderContext } from 'mobx-react';
import _ from 'lodash';
import { Dropdown } from 'primereact/dropdown';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';


import { CompLoading } from '../../Loaders';
import { FINCAS_LIST } from '../../../queries/queries_finca';
import Err from '../../Err';


function FincaSelectorGlobal() {
    const { t } = useTranslation();
    const idInput = '_' + Math.round(Math.random() * 10000000000);

    const { GlobalStore } = useContext(MobXProviderContext);

    const { loading, error, data, refetch } = useQuery(FINCAS_LIST, {
        variables: {
            uid: GlobalStore.user.uid,
        }
        // pollInterval: 500,
    });

    if (loading) {
        return <CompLoading />;
    }

    if (error) {
        return <Err error={error} />;
    }

    const options = _.map(data.fincas, ({ id, nombreFinca }) => {
        return {
            label: nombreFinca,
            value: id,
        };
    });

    const { fincaId, setFincaId } = GlobalStore;

    const handleChange = ({ target: { value } }) => {
        const fincaObj = _.find(options, { value });
        setFincaId(fincaObj);
    };

    const handleValue = () => {
        if (_.isEmpty(fincaId)) return null;
        return fincaId.value;
    };

    const reloadData = () => {
        refetch();
    };

    return (
        <Dropdown
            name="fincaGlobal"
            id="fincaGlobal"
            inputId={idInput}
            value={handleValue()}
            onChange={handleChange}
            onMouseDown={reloadData}
            options={options}
            placeholder={t('dashboard.select_farm')}
        />
    );
}

export default observer(FincaSelectorGlobal);

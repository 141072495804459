import { gql } from '@apollo/client';

const COMBUSTION_MOVIL_CALC = gql`
  query CalcCombustionMovil(
    $combustible: String!
    $consumo: Float!
    $empresa: ID!
  ) {
    calculoCombustionMovil(
      combustible: $combustible
      consumo: $consumo
      empresa: $empresa
    )
  }
`;

export { COMBUSTION_MOVIL_CALC };

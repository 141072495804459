////@ts-check

import React, { useState, useContext, useEffect } from 'react';
import { MobXProviderContext } from "mobx-react";
import _ from 'lodash';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { Dialog } from 'primereact/dialog';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';


import { CompLoading } from '../../../Loaders';
import { useGetTableData } from '../../hooks';
import FieldBuilder from '../../../../helpers/FieldBuilder';
import validationSchema from '../../../../helpers/validationSchema';
import initialValues from '../../../../helpers/initialValues';
import { numberFormatTxt } from '../../../../helpers/rounder';

import './tableBuilder.scss';

export default function TableBuilder({ id, def, tableName }) {
    // === Variables globales y diccionario ===
    const { GlobalStore } = useContext(MobXProviderContext);
    const { t, i18n } = useTranslation();
    // === Variables de estado ===
    const [response, setResponse] = useState(null);
    const [ButtonNewRowDialog, setButtonNewRowDialog] = useState(true);  // setButtonNewRowDialog
    const [editRowDialog, setEditRowDialog] = useState(false);
    const [elemRow, setElemRow] = useState(null);
    // === Conexión a la base de datos ===
    const { db } = GlobalStore.firebase;
    const tableRef = `configuraciones_${tableName}`;
    const [loading, data, setData, setLoading] = useGetTableData(tableRef, id); // setLoading, setData

    // Comprobación inicial

    useEffect(() => {
        const sinAddRegister = ['table1', 'table3', 'table10'];
        if (sinAddRegister.indexOf(tableName) !== -1) {
            setButtonNewRowDialog(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


    // === Gestión de mensaje de carga/actualización de la tabla
    const flashResponse = res => {
        setResponse(res);
        setTimeout(() => {
            setResponse(null);
        }, 3000);
    };

    // === Funciones de acceso a la base de datos (add/update)

    const eliminateNulls = (obj) => {
        const o = {};
        Object.keys(obj).forEach(el => {
            if (obj[el] !== undefined && obj[el] !== null) {
                o[el] = obj[el];
            }
        });
        return o;
    };

    const updateRow = async (obj) => {
        const o = { ...elemRow };
        Object.assign(o, obj);
        const oId = o.id;
        delete o.id;
        await db.collection(tableRef).doc(oId).set(eliminateNulls(o));
        flashResponse(t('saved_successfully'));
        let updatedData = [...data];
        const i = updatedData.findIndex(el => el.id === oId);
        updatedData[i] = o;
        setData(updatedData);
        setElemRow(null);
        setLoading(true);
    };

    const addRow = async (obj) => {
        const o = { ...obj, configuracion: db.doc(`configuraciones/${id}`) };
        const ob = await db.collection(tableRef).add(eliminateNulls(o));
        flashResponse(t('saved_successfully'));
        let updatedData = [...data];
        updatedData.push({ ...o, id: ob.id });
        setData(updatedData);
        setElemRow(null);
        setLoading(true);
    };

    const deleteRow = async () => {
        const o = { ...elemRow };
        const collectionRef = db.collection(tableRef).doc(o.id);
        await collectionRef.delete();
        setElemRow(null);
        setLoading(true);
    };

    // === Funciones de gestión de tabla ===

    const campos = [{
        type: "numberInt",
        fieldname: "row",
        step: 1
    }].concat(def);

    const traduceTituloColumna = (etq) => {
        if (i18n.exists('rename.' + tableName + '_' + etq)) {
            return t('rename.' + tableName + '_' + etq);
        }
        return t('tables.' + etq);
    };

    const columnsFromDefS = _.map(campos, (fld) => {
        if (fld.translate) {
            return <Column
                key={fld.fieldname}
                field={fld.fieldname}
                header={traduceTituloColumna(fld.fieldname)}
                body={(dataRow) => cellTranslate(fld.fieldname, dataRow[fld.fieldname])}
                sortable />;
        }
        if (fld.type === 'number' || fld.type === 'numberInt' || fld.type === 'numberUnits') {
            let mf = 2;
            if (fld.step) {
                const tn = (fld.step - fld.step.toFixed()).toString().split('-');
                mf = (tn.length>1) ? 1*tn[1] : tn[0].length - 2;
            }
            mf = (mf < 0) ? 0 : mf;
            return <Column
                key={fld.fieldname}
                field={fld.fieldname}
                header={traduceTituloColumna(fld.fieldname)}
                body={(dataRow) => numberFormatTxt(dataRow[fld.fieldname], mf)}
                sortable />;
        }
        return <Column key={fld.fieldname} field={fld.fieldname} header={traduceTituloColumna(fld.fieldname)} sortable />;
    });

    const cellEditTemplate = (dataRow) => {
        return (<button type="button" className="p-row-editor-init p-link" onClick={() => openRowDialog(dataRow)}>
            <span className="p-row-editor-init-icon pi pi-fw pi-pencil"></span>
        </button>);
    };

    const cellTranslate = (fieldkey, fieldData) => {
        if (i18n.exists('domains:' + fieldkey + '.' + fieldData)) {
            return t('domains:' + fieldkey + '.' + fieldData);
        }
        // console.log('(Traducir) fieldkey: ', fieldkey, ' => ', fieldData);
        return fieldData;
    };

    // === Funciones de gestión de la ventana modal de Update/add

    const defcampos = _.map(campos, ({ fieldname, type, readOnly, required, options, min, max, step, visibleCondicional, customLabel, unique, defaultValue, disabled }) => {
        const tx = (type === 'string') ? 'text' : type;
        const o = {
            type: tx || "text",
            fieldname: fieldname,
            label: t('tables.' + fieldname)
        };
        options && (o.options = options);
        required && (o.required = true);
        visibleCondicional && (o.visibleCondicional = visibleCondicional);
        customLabel && (o.customLabel = customLabel);
        defaultValue && (o.defaultValue = defaultValue);
        disabled && (o.disabled = true);
        (readOnly && elemRow) && (o.disabled = true);
        ((min !== undefined)&&(min !== null)) && (o.min = min);
        ((max !== undefined)&&(max !== null)) && (o.max = max);
        ((step !== undefined)&&(step !== null)) && (o.step = step);
        if (unique && !elemRow) {
            o.arrayExclusion = data.map(r => r[fieldname]);
        }
        return o;
    });

    const initValues = (dc) => {
        const r = initialValues(dc);
        if (elemRow) {
            Object.keys(r).forEach(k => { r[k] = elemRow[k]; });
        } else {
            r.row = data.reduce((a, b) => a.row > b.row ? a : b).row + 1;
        }
        return r;
    };

    const valSchema = (dc) => {
        const r = validationSchema(dc);
        return r;
    };

    const openRowDialog = (elRow) => {
        setElemRow(elRow);
        setEditRowDialog(true);
    };

    const saveEditRowDialog = (values) => {
        // console.log('saveEditRowDialog: ', values);
        elemRow ? updateRow(values) : addRow(values);
        setEditRowDialog(false);
    };

    const deleteRowDialog = () => {
        deleteRow();
        setEditRowDialog(false);
    };

    // =============================================================================
    // =============================================================================

    if (loading && !data) {
        return <CompLoading />;
    }

    return (
        <div>
            {response && (
                <div style={{ marginBottom: 10 }}>
                    <Message severity="success" text={response}></Message>
                </div>
            )}
            <DataTable
                value={data}
                enableReinitialize={true}
                responsive={true}>
                {columnsFromDefS}
                <Column
                    headerStyle={{ width: '4rem', textAlign: 'center' }}
                    bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
                    body={cellEditTemplate} />
            </DataTable>
            {ButtonNewRowDialog && (<Button
                type="button"
                label={t('common:add_record')}
                className="p-button-raised p-button-rounded"
                icon="pi pi-plus"
                iconPos="right"
                style={{ marginBottom: 15 }}
                onClick={() => openRowDialog()}
            />)}

            {editRowDialog && (<Dialog
                header={t('common:' + (elemRow ? 'update_record' : 'add_record'))}
                visible={editRowDialog}
                style={{ width: '450px' }}
                className="p-fluid dialog-edit"
                onHide={() => setEditRowDialog(false)}
            >
                <Formik
                    initialValues={initValues(defcampos)}
                    validationSchema={valSchema(defcampos)}
                    enableReinitialize={true}
                    onSubmit={(values) => saveEditRowDialog(values)}
                >
                    {(formik) => {
                        GlobalStore.setFormik(formik);
                        return (
                            <form autoComplete="off" onSubmit={formik.handleSubmit}>
                                <div className="cuerpo">
                                    <FieldBuilder def={defcampos} />
                                </div>
                                <div className="p-dialog-footer">
                                    {elemRow && (!elemRow.label) && (<Button label={t('common:delete')} icon="pi pi-trush" onClick={() => deleteRowDialog(false)} className="p-button-text" />)}
                                    <Button label={t('common:cancelar')} icon="pi pi-times" onClick={() => setEditRowDialog(false)} className="p-button-text" />
                                    <Button label={t('common:' + (elemRow ? 'update_record' : 'add_record'))} icon="pi pi-check" type="submit" disabled={!formik.isValid} />
                                </div>
                            </form>
                        );
                    }}
                </Formik>
            </Dialog>)}

        </div>
    );
}


////@ts-check

import React, { useState, useContext } from "react";
import { MobXProviderContext } from "mobx-react";
import { useQuery } from '@apollo/client';
import { TabMenu } from 'primereact/tabmenu';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';


import * as ROUTES from "../../../../constants/routes";
import { RESULTS_VAR } from "../../../../queries/queries_results";
import { CompLoading } from "../../../Loaders";
import Err from "../../../Err";

import HuellaAguaBloque from "./HuellaAguaBloque";
import HuellaCarbonoBloque from "./HuellaCarbonoBloque";

import {
    HandleConfigSelect,
    HandleAnioSelect,
    HandleMesSelect,
    HandleFincaSelect,
    HandleAlcanceSelect,
    HandleFuenteSelect
} from "./ResultsFiltros";

import "./results.scss";


export default function Results() {
    const { GlobalStore } = useContext(MobXProviderContext);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [type, setType] = useState('CARBONO');
    const [config, setConfig] = useState(null);
    const [finca, setFinca] = useState({id:null, nombreFinca: null});
    const [anio, setAnio] = useState(null);
    const [mes, setMes] = useState(null);
    const [alcance, setAlcance] = useState(null);
    const [fuente, setFuente] = useState(null);

    const { loading, error, data } = useQuery(RESULTS_VAR, {
        variables: {
            uid: GlobalStore.user.uid,
            empresa: GlobalStore.compId
        }
    });

    if (GlobalStore.userType !== 2) {
        navigate(ROUTES.DASHBOARD);
    }

    if (loading) {
        return <CompLoading />;
    }

    if (error) {
        return <Err error={error} />;
    }

    /**
     * Selector
     * @returns Selector Huella de agua / Huella de Carbono
     */
     const handleTypeSelect = () => {
        const tabs = ['AGUA', 'CARBONO'];
        const options = [
            { label: t('crud.water_footprint'), value: 'AGUA' },
            { label: t('crud.carbon_footprint'), value: 'CARBONO' }
        ];
        return ( <TabMenu activeIndex={tabs.indexOf(type)} onTabChange={(e) => setType(e.value.value)} model={options} /> );
    };

    /**
     * Construye los filtros
     * @returns
     */
    const showFilters = () => {
        return (
            <div className="filters">
                <h3>
                    <i className="pi pi-filter" />
                    {t('common:filters')}
                </h3>
                <div className="filters-wrapper">
                    <HandleConfigSelect configuraciones={data.configuraciones} config={config} setConfig={setConfig}></HandleConfigSelect>
                    <HandleAnioSelect anio={anio} setAnio={setAnio}></HandleAnioSelect>
                    <HandleMesSelect mes={mes} setMes={setMes}></HandleMesSelect>
                    <HandleFincaSelect fincas={data.fincas} finca={finca} setFinca={setFinca}></HandleFincaSelect>
                    <HandleAlcanceSelect type={type} alcance={alcance} setAlcance={setAlcance}></HandleAlcanceSelect>
                    <HandleFuenteSelect type={type} fuente={fuente} setFuente={setFuente}></HandleFuenteSelect>
                </div>
            </div>
        );
    };

    const showBlock = () => {
        if (config === null || anio === null) return null;
        const datos = {
            fincaName: (finca)?finca.nombreFinca:null,
            companyName: data.empresa.nombreEmpresa,
            tecnicoName: GlobalStore.user.displayName
        };
        switch (type) {
            case 'AGUA':
                return (
                    <HuellaAguaBloque
                        configuracion={config}
                        empresa={GlobalStore.compId}
                        finca={(finca)?finca.id:null}
                        anio={anio.toString()}
                        mes={mes}
                        fuente={fuente}
                        datos={datos}
                    />
                );
            case 'CARBONO':
                return (
                    <HuellaCarbonoBloque
                        configuracion={config}
                        empresa={GlobalStore.compId}
                        finca={(finca)?finca.id:null}
                        anio={anio.toString()}
                        mes={mes}
                        alcance={alcance}
                        datos={datos}
                    />
                );
            default:
                return null;
        }
    };

    //TODO: Buscar y poner en función del idioma el documento pdf
    return (
        <div className="results-container" mdl="src/components/CRUD/Read/Results/index">
            <h1> {t('results.select_the_type_of_report')} </h1>
            <div className="excerpt">
                <h4> {t('results.dear_user')} </h4>
                <Trans i18nKey="results.recommendation_note">
                    a
                    <a href="http://www.fao.org/3/I8333ES/i8333es.pdf" target="_blank" rel="noopener noreferrer">
                    a
                    </a>
                    a
                </Trans>

            </div>
            {handleTypeSelect()}
            {showFilters()}
            {showBlock()}
        </div>
    );
}

////@ts-check

import React, { useContext } from 'react';
import OpMenuDashboard from '../../Menus/OpMenuDashboard';
import { MobXProviderContext } from 'mobx-react';
import { Helmet } from 'react-helmet-async';

import { useTranslation } from 'react-i18next';

import './user-op.scss';

export default function UserOp() {
    const { GlobalStore } = useContext(MobXProviderContext);
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Helmet>
                <title>
                    {t('dashboard.userop_title')}
                </title>
            </Helmet>
            <h1> {t('dashboard.userop_type')} </h1>
            <h2> {t('common:welcome')}, {GlobalStore.user.displayName} </h2>
            <div className="note-digits">
                {t('dashboard.note_digits')}
            </div>
            <div className="op-forms-grid">
                <OpMenuDashboard />
            </div>
        </React.Fragment>
    );
}

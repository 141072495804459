////@ts-check

import React, {useRef, useState} from 'react';
import _ from 'lodash';
import { DataTable } from 'primereact/datatable';
import { ColumnGroup } from 'primereact/columngroup';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';

import { rounder, numberFormatTxt } from '../../../../../helpers/rounder';

export default function HuellaCarbonoResumenTabla1({data, generatePdf, refTabla}) {
    const { t, i18n } = useTranslation();
    const dt = useRef(null);

    const [exportando, setExportando] = useState(false);
    const { columns, footer } = data;


    const columnasExportables = (columns) => _.map(columns, (col, i) => (
        <Column key={i} field={col.field} header={t('tables.h_'+col.field)+' ('+t('tables.h_'+col.field+'_u')+')'} align={i?'center':'left'} alignHeader="center"/>
    ));

    const traduceFuenteEmision = () => {
        _.map(values, (value) => {
            if (i18n.exists('menu.'+value['fuenteEmision'])) {
                value['fuenteEmision'] = t('menu.' + value['fuenteEmision']);
            }
        });
    };

    const columnasVisibles = (columns) => {
        const getHeader = (col) => {
            const u = t('tables.h_'+col.field+'_u');
            return (<> {t('tables.h_'+col.field)} <br/><small> {u===''?'':'('+u+')'} </small> </>);
        };
        return _.map(columns, (col, i) => {
            const r = getHeader(col);
            return (<Column key={i} field={col.field} header={r} align={i?'center':'left'} alignHeader="center"/>);
        });
    };

    const footerGroup = (footer) => {
        if (!footer) return null;
        const valuesFoot = _.map(footer.values, (value, i) => {
            const roundedValue = numberFormatTxt(value);
            return <Column footer={roundedValue} key={i} align="center" />;
        });

        return (
            <ColumnGroup>
                <Row>
                    <Column footer={footer.label} align="center" />
                    {valuesFoot}
                </Row>
            </ColumnGroup>
        );
    };

    const exportCSV = async (selectionOnly) => {
        await setExportando(true);
        dt.current.exportCSV({ selectionOnly });
    };

    // https://stackoverflow.com/questions/50147526/sheetjs-xlsx-cell-styling
    // https://github.com/ShanaMaid/sheetjs-style
    const exportExcel = () => {
        // console.log('xslx: ', values);
        import('sheetjs-style').then(xlsx => {
            const wb = xlsx.utils.book_new();
            const ws = xlsx.utils.json_to_sheet(values);
            xlsx.utils.book_append_sheet(wb, ws, 'sheet1');
            xlsx.writeFile(wb, 'test.xlsx');
        });
    };

    /*
    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(() => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            window.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    };
*/

    // <Button type="button" icon="pi pi-file-pdf" onClick={exportPdf} className="p-button-warning mr-2" data-pr-tooltip="PDF" />
    // <Button type="button" icon="pi pi-filter" onClick={() => exportCSV(true)} className="p-button-info ml-auto" data-pr-tooltip="Selection Only" />

    const values = JSON.parse(JSON.stringify(data.values));
    if (values && values.length === 0) {
        return (
            <div className="sinDatos">
                <span> { t('common:no_data') } </span>
            </div>
        );
    }

    traduceFuenteEmision();
    const roundedData = rounder(values, 10);

    const header = (
        <div className="flex align-items-center export-buttons">
            <Button type="button" icon="pi pi-file" onClick={() => exportCSV(false)} className="mr-2" title={t('commons:export_csv')} />
            &nbsp;
            <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="mr-2" title={t('commons:export_xls')} />
            &nbsp;
            <Button type="button" icon="pi pi-file-pdf" onClick={generatePdf} className="mr-2" title={t('commons:export_pdf')} />
        </div>
    );


    return (
        <div className="tabla_results">
            <DataTable id={refTabla} value={roundedData} footerColumnGroup={footerGroup(footer)} header={header}>
                {columnasVisibles(columns)}
            </DataTable>
            {exportando && (<div style={{display:"none"}}>
                <DataTable ref={dt} value={roundedData} footerColumnGroup={footerGroup(footer)}>
                    {columnasExportables(columns)}
                </DataTable>
            </div>)}
        </div>
    );
}

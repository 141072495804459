////@ts-check

import * as Yup from "yup";
import _ from "lodash";

const TXT_REQUIRED = 'field_required';

function handleNumConds(field, fieldValue, parentFieldname) {
    if (field.positive) {
        return {
            [field.fieldname]: Yup.number().when(parentFieldname, {
                is: fieldValue,
                then: Yup.number()
                    .required(TXT_REQUIRED)
                    .positive("field_positive")
            })
        };
    }
    if (field.zeroMin) {
        return {
            [field.fieldname]: Yup.number().when(parentFieldname, {
                is: fieldValue,
                then: Yup.number()
                    .required(TXT_REQUIRED)
                    .min(0, `Mínimo: 0`)
            })
        };
    }
    if (field.min || field.max) {
        return {
            [field.fieldname]: Yup.number().when(parentFieldname, {
                is: fieldValue,
                then: Yup.number()
                    .required(TXT_REQUIRED)
                    .min(field.min, `Mínimo: ${field.min}`)
                    .max(field.max, `Máximo: ${field.max}`)
            })
        };
    }
    return {
        [field.fieldname]: Yup.number().when(parentFieldname, {
            is: fieldValue,
            then: Yup.number().required(TXT_REQUIRED)
        })
    };
}

function validatePerTypeCond(acc, field, fieldValue, parentFieldname) {
    switch (field.type) {
        case "number":
            return {
                ...acc,
                ...handleNumConds(field, fieldValue, parentFieldname)
            };
        default:
            return {
                ...acc,
                [field.fieldname]: Yup.string().when(parentFieldname, {
                    is: fieldValue,
                    then: Yup.string().required(TXT_REQUIRED)
                })
            };
    }
}

function handleCondField(field) {
    const { dependents, fieldname: parentFieldname } = field;
    const depList = _.map(dependents, ({ depFields, fieldValue }) => {
        return _.reduce(
            depFields,
            (acc, field) => validatePerTypeCond(acc, field, fieldValue, parentFieldname),
            {}
        );
    });
    return _.reduce(
        depList,
        (acc, obj) => {
            return {
                ...acc,
                ...obj
            };
        },
        {}
    );
}

function handleVisibleCondicional(acc, field, def) {
    const keys = Object.keys(field.visibleCondicional);
    for (let i = 0; i < keys.length; i++) {
        if (!acc[keys[i]]) {
            acc = validatePerType(acc, def[keys[i]]);
        }
    }
    const fd = field; // Para emular función flecha. "arguments" no funciona con funciones flecha
    return {
        ...acc,
        [field.fieldname]: Yup.mixed().when(keys, {
            is: function () {
                for (let i = 0; i < keys.length; i++) {
                    if (arguments[i] && (fd.visibleCondicional[keys[i]].indexOf(arguments[i]) > -1)) {
                        return true;
                    }
                }
                return false;
            },
            then: validatePerType(acc, fd)[field.fieldname],
            // eslint-disable-next-line no-unused-vars
            otherwise: Yup.mixed().notRequired() // nullable(true).transform((_, val) => null)
        })
    };
}

function handleNum(field) {
    let v = Yup.number();
    v = (field.required) ? v.required(TXT_REQUIRED) : v;
    v = (field.positive) ? v.required(field.positive[1]) : v;
    v = (field.min) ? v.min(field.min, 'field_min:' + field.min) : v;
    v = (field.max) ? v.max(field.max, 'field_max:' + field.max) : v;
    return {
        [field.fieldname]: v
    };
}

function handleText(field) {
    let v = Yup.string();
    v = (field.required) ? v.required(TXT_REQUIRED) : v;
    v = (field.min) ? v.min(+field.min[0], field.min[1] + ':' + field.min[0]) : v;
    v = (field.max) ? v.max(+field.max[0], field.max[1] + ':' + field.max[0]) : v;
    if (field.arrayExclusion) {
        // @ts-ignore
        v = v.test("NoDuplicate", "field_duplicate", val => val && !(field.arrayExclusion.find(t => t.trim() === val.trim())));
    }
    return {
        [field.fieldname]: v
    };
}

function validatePerType(acc, field) {
    if (!field.required) {
        return acc;
    }
    switch (field.type) {
        case "date":
            return {
                ...acc,
                [field.fieldname]: Yup.date()
                    .required(TXT_REQUIRED)
                    .nullable(true)
            };
        case "text":
            return {
                ...acc,
                ...handleText(field)
            };
        case "number":
            return {
                ...acc,
                ...handleNum(field)
            };
        case "conditional":
            return {
                ...acc,
                [field.fieldname]: Yup.string().required(TXT_REQUIRED),
                ...handleCondField(field)
            };
        default:
            return {
                ...acc,
                [field.fieldname]: Yup.string().required(TXT_REQUIRED)
            };
    }
}

export default function validationSchema(def) {
    const yupShapeObj = _.reduce(
        def,
        (acc, field) => {
            const skip = field.type === "hidden" || field.type === "calculo";
            if (!skip) {
                return (field.visibleCondicional)
                    ? handleVisibleCondicional(acc, field, def)
                    : validatePerType(acc, field);
            }
            return acc;
        },
        {}
    );
    return Yup.object().shape(yupShapeObj);
}

////@ts-check

import React, { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { Calendar } from 'primereact/calendar';
import { useTranslation } from "react-i18next";


import HandleLabel from './HandleLabel';
import Error from './Error';


export default function Date({ field, required, disabled }) {
    const {
        GlobalStore: { formik },
    } = useContext(MobXProviderContext);
    const { fieldname, dateFormat } = field;
    const { handleChange, values, touched, errors, isValid } = formik;
    const vista = (dateFormat === 'mm/yy') ? 'month' : 'date';
    const { i18n } = useTranslation();

    return (
        <div>
            <label htmlFor={fieldname} className={`required-${required}`}>
                <HandleLabel field={field} formik={formik}></HandleLabel>
            </label>
            <Calendar
                view={vista}
                locale={i18n.language.split('-')[0]}
                dateFormat={dateFormat}
                name={fieldname}
                id={fieldname}
                onChange={handleChange}
                value={values[fieldname]}
                disabled={disabled}
            />
            <Error
                isValid={isValid}
                touched={touched[fieldname]}
                errors={errors[fieldname]}
            />
        </div>
    );
}

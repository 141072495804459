////@ts-check

import React from "react";
import Modal from "react-modal";
import { Button } from "primereact/button";

import { useTranslation } from 'react-i18next';

import WBF from "./images/WBF_g.png";
import GestarseLogo from "./images/Gestarse-logo.png";
import UCLM from "./images/UCLM.png";
import IDR from "./images/IDR.png";
import LCM from "./images/LCM.png";

//TODOo: Falta por Traducir

export default function Reconocimientos({ modalIsOpen, closeModal }) {
    const { t } = useTranslation();
    return (
        <Modal
            isOpen={modalIsOpen}
            ariaHideApp={false}
            onRequestClose={closeModal}
            contentLabel={t('common:about')}
        >
            <Button
                type="button"
                label={t('common:close')}
                className="p-button-secondary"
                icon="pi pi-times"
                onClick={() => closeModal()}
            />
            <h1>{t('common:about')}</h1>
            <div className="modal-content">
                <p> {t('login.about1')} </p>
                <p> {t('login.about2')} </p>
                <p> {t('login.about3')} </p>
                <p> {t('login.about4')} </p>
            </div>
            <div className="modal-logos">
                <div className="main">
                    <img src={WBF} alt="WORLD BANANA FORUM (WBF)" title="WORLD BANANA FORUM (WBF)"/>
                    <img src={GestarseLogo} alt="Gestarse" title="Gestarse"/>
                    <div title="Teledetección y SIG &#10;Instituto de desarrollo Regional (IDR) &#10;Universidad Castilla-La Mancha (UCLM)">
                        <img src={UCLM} alt="Universidad Castilla-La Mancha (UCLM)"/>
                        <img src={IDR} alt="Teledetección y SIG - Instituto de desarrollo Regional (IDR)"/>
                    </div>
                    <img src={LCM} alt="Costa Rican Metrology Laboratory of the Ministry of Economy, Industry and Trade (LCM)" title="Costa Rican Metrology Laboratory of the Ministry of Economy, Industry and Trade (LCM)"/>
                </div>
            </div>
        </Modal>
    );
}

import { gql } from '@apollo/client';

const HUELLA_AGUA_CALC = gql`
  query CalcHuellaAgua(
    $sistema: String!
    $volumenMensualEvapotranspiracionCultivo: Float
    $volumenEntradaAgua: Float
    $volumenSalidaAgua: Float
    $producto: String
    $aplicacionProducto: Float
    $areaTotalCultivo: Float
    $sustanciaFosforo: String
    $compartimientoFosforo: String
    $aplicacionFosforo: Float
    $dboPromedio: Float
    $fecha: String
    $region: String
    $empresa: ID!
  ) {
    calculoHuellaAgua(
      sistema: $sistema
      volumenMensualEvapotranspiracionCultivo: $volumenMensualEvapotranspiracionCultivo
      volumenEntradaAgua: $volumenEntradaAgua
      volumenSalidaAgua: $volumenSalidaAgua
      producto: $producto
      aplicacionProducto: $aplicacionProducto
      areaTotalCultivo: $areaTotalCultivo
      sustanciaFosforo: $sustanciaFosforo
      compartimientoFosforo: $compartimientoFosforo
      aplicacionFosforo: $aplicacionFosforo
      dboPromedio: $dboPromedio
      fechaMes: $fecha
      region: $region
      empresa: $empresa
    )
  }
`;

export { HUELLA_AGUA_CALC };

////@ts-check

import React from 'react';

import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { REPORT_HUELLA_CARBONO } from '../../../../queries/queries_results';
import { CompLoading } from '../../../Loaders';
import ChartDoughnutTabla1Contenedor from './graficas/ChartDoughnutTabla1Contenedor';
import ChartColumnsTabla1Contenedor from './graficas/ChartColumnsTabla1Contenedor';
import HuellaCarbonoResumenTabla1 from './tablas/HuellaCarbonoResumenTabla1';
import Err from '../../../Err';

export default function HuellaCarbonoResumen1({requestData}) {
    const { t, i18n } = useTranslation();

    const { loading, error, data } = useQuery(REPORT_HUELLA_CARBONO, {
        fetchPolicy: 'no-cache',
        variables: {
            ...requestData,
            formato: 'resumen' // ['resumen' | 'producto' | 'subfuente']
        }
    });

    if (loading) {
        return <CompLoading />;
    }

    if (error) {
        return <Err error={error} />;
    }

    const dataParsed = JSON.parse(data.reporteHuellaCarbono);

    const generarPdf = () => {
        const refTablaA = document.querySelector('#tablaResumenA table.p-datatable-table');
        const refTablaB = document.querySelector('#tablaResumenB table.p-datatable-table');
        if (refTablaA && refTablaB) {
            // Preparación de la tabla para incorporarla al pdf
            refTablaA.setAttribute('id', 'reftablaResumenA');
            refTablaA.querySelectorAll('#reftablaResumenA .p-datatable-tbody>tr>td>.p-column-title').forEach(el => el.remove());
            refTablaB.setAttribute('id', 'reftablaResumenB');
            refTablaB.querySelectorAll('#reftablaResumenB .p-datatable-tbody>tr>td>.p-column-title').forEach(el => el.remove());
            // Lazyload
            import('./utils_pdf').then(({ createDocPdf, addHeaders, addFooters, add2ChartsPngToPdf, createTablePdf, addTablaFiltroPdf }) => {
                const doc = createDocPdf('landscape');
                doc.setFont('helvetica', 'italic');
                let finalY = 17;
                doc.setFontSize(14);
                doc.setTextColor(30,30,50);
                // @ts-ignore
                doc.text(t('dashboard.huella_carbono'), 145, finalY+8, null, null, 'center');
                finalY = addTablaFiltroPdf({
                    doc: doc,
                    data: {
                        empresa: { title: t('field.company'), value: requestData.datos.companyName},
                        tecnico: { title: t('field.tecnico'), value: requestData.datos.tecnicoName},
                        alcance: { title: t('fields.alcance'), value: requestData.alcance?t('domains:alcance.'+requestData.alcance): t('crud.all_') },
                        anno: { title: t('field.year'), value: requestData.anio},
                        mes: { title: t('field.month'), value: requestData.mes?requestData.mes:t('crud.all_') },
                        finca: { title: t('fields.finca'), value: requestData.datos.fincaName || t('crud.all_') }
                    },
                    config: { startY: finalY + 15 }
                });
                doc.setFontSize(11);
                doc.setTextColor(70,70,90);
                doc.text(t('results.results_at_the_emissions'), 14, finalY + 10);
                finalY = createTablePdf({
                    doc: doc,
                    config: {
                        html: '#reftablaResumenA',
                        startY: finalY + 12,
                        margin: { top: 20 },
                        styles: { fontSize: 6.5, cellPadding: 1 },
                        headStyles: { halign: 'center', cellPadding: 1 }, // ?????????????????
                        bodyStyles: { halign: 'center', textColor: 10 },
                        footStyles: { halign: 'center' },
                        columnStyles: { 0: {halign: 'left'}}
                    }
                });
                finalY = add2ChartsPngToPdf({doc: doc, finalY: finalY, class: 'graficaWrapperTablaA' });
                doc.setFontSize(11);
                doc.setTextColor(70,70,90);
                doc.text(t('results.results_at_the_emissions'), 14, finalY + 10);
                finalY = createTablePdf({
                    doc: doc,
                    config: {
                        html: '#reftablaResumenB',
                        startY: finalY + 12,
                        margin: { top: 20 },
                        styles: { fontSize: 6.5, cellPadding: 1 },
                        headStyles: { halign: 'center', cellPadding: 1 }, // ?????????????????
                        bodyStyles: { halign: 'center', textColor: 10 },
                        footStyles: { halign: 'center' },
                        columnStyles: { 0: {halign: 'left'}}
                    }
                });
                finalY = add2ChartsPngToPdf({doc: doc, finalY: finalY, class: 'graficaWrapperTablaB' });
                addHeaders(doc, i18n.language.split('-')[0]);
                addFooters(doc);
                let name = (i18n.language.split('-')[0] === 'es')
                    ? 'WBF_ResumenHuellaCarbono_PorFuenteDeEmision_'
                    : 'WBF_SummaryCarbonFootprint_ByEmissionSource_';
                doc.save(name + (new Date()).toISOString().split('.')[0].replace(/-|:/g,'') + '.pdf');
            }).catch(err => {
                console.log('--------------------------------------------');
                console.error(err);
                console.log('--------------------------------------------');
            });
        }
    };

    return (
        <div className="result-item results-1">
            <h3 onClick={generarPdf}> {t('results.results_at_the_emissions')} </h3>
            <HuellaCarbonoResumenTabla1 data={{...dataParsed.general, formulario: requestData.formulario}} generatePdf={generarPdf} refTabla={'tablaResumenA'}/>
            <ChartDoughnutTabla1Contenedor data={{...dataParsed.general, title: 'emisiones_de_gei_por_fuente_de_emision'}} />
            <br/>
            <HuellaCarbonoResumenTabla1 data={dataParsed.usoSuelo} generatePdf={generarPdf} refTabla={'tablaResumenB'}/>
            <ChartColumnsTabla1Contenedor data={{...dataParsed.usoSuelo, title: 'emisiones_de_gei_por_uso_de_suelo'}} />
        </div>
    );
}

//@ts-check

import React from 'react';

import { inject, observer } from 'mobx-react';

import Login from '../Login';
import Logged from './Logged';
import { GlobalLoading } from '../Loaders';

import './app.scss';

function App({ GlobalStore: { user, globalLoading } }) {
    // @ts-ignore
    return (globalLoading) ? <GlobalLoading /> : (!user) ? <Login /> : <Logged />;
}

export default inject('GlobalStore')(observer(App));

import { gql } from '@apollo/client';

const FORM_DEF = gql`
  query NewForm($tipoDefinicion: String!, $empresa: ID) {
    definicion(
      tipoDefinicion: $tipoDefinicion
      empresa: $empresa
    )
  }
`;

const FORM_GET = gql`
  query GetForm($id: ID!, $tipoDefinicion: String!, $empresa: ID) {
    formulario(id: $id) {
      id
      camposFormulario
      tipoFormulario
    }
    definicion(
      tipoDefinicion: $tipoDefinicion
      empresa: $empresa
    )
  }
`;

const FORM_EDIT = gql`
  mutation EditForm(
    $id: ID!
    $uid: ID!
    $tipoFormulario: String!
    $camposFormulario: String!
  ) {
    formularioActualizar(
      id: $id
      uid: $uid
      tipoFormulario: $tipoFormulario
      camposFormulario: $camposFormulario
    ) {
      id
    }
  }
`;

const FORM_ADD = gql`
  mutation AddForm(
    $uid: ID!
    $tipoFormulario: String!
    $finca: ID!
    $camposFormulario: String!
  ) {
    formularioAgregar(
      uid: $uid
      tipoFormulario: $tipoFormulario
      finca: $finca
      camposFormulario: $camposFormulario
    ) {
      id
    }
  }
`;

const FORM_LIST = gql`
  query FormList($uid: ID!, $tipoFormulario: String, $finca: ID) {
    formularios(uid: $uid, tipoFormulario: $tipoFormulario, finca: $finca) {
      id
      tipoFormulario
      fechaInputacion
      fechaActualizacion
      finca {
        nombreFinca
      }
      creadoPor {
        displayName
        id
      }
      consumo
      fuente
      alcance
      proveedor
      centroTrabajo
      numeroDocumento
    }
  }
`;

const FORM_DELETE = gql`
  mutation FormDelete($id: ID!, $uid: ID!) {
    formularioBorrar(id: $id, uid: $uid)
  }
`;

const FORM_DUPLICATE = gql`
  mutation FormDuplicate($id: ID!, $uid: ID!) {
    formularioClonar(id: $id, uid: $uid)
  }
`;

export {
  FORM_DEF,
  FORM_GET,
  FORM_EDIT,
  FORM_ADD,
  FORM_LIST,
  FORM_DELETE,
  FORM_DUPLICATE,
};

////@ts-check

import React, { useContext, useState } from 'react';
import { MobXProviderContext } from "mobx-react";
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';


import Header from './Header';
import Footer from './Footer';
import ResetPassword from './ResetPassword';
import Err from '../Err';

import './login.scss';


export default function Login() {
    const { GlobalStore } = useContext(MobXProviderContext);
    const { firebase } = GlobalStore;

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');

    const { t } = useTranslation();

    const handleSubmit = async () => {
        setLoading(true);
        try {
            await firebase.auth.signInWithEmailAndPassword(user, password);
        } catch (error) {
            switch (error.code) {
                case 'auth/user-not-found':
                    error.message = t('error:auth_user_not_found');
                    break;

                case 'auth/wrong-password':
                    error.message = t('error:auth_wrong_password');
                    break;

                case 'auth/invalid-email':
                    error.message = t('error:auth_invalid_email');
                    break;

                case 'auth/too-many-requests':
                    error.message = t('error:auth_too_many_requests');
                    break;

                default:
                    break;
            }
            setError(error);
        }
        return setLoading(false);
    };

    return (
        <React.Fragment>
            <Header />
            <div className="login">
                <h1> {t('login.titleL1')} <br/> {t('login.titleL2')} </h1>
                <h2> {t('login.subtitle')} </h2>
                <Err error={error} />
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                        <i className="pi pi-user" />
                    </span>
                    <InputText
                        onChange={(e) => setUser(e.target.value)}
                        placeholder={t('common:user')}
                    />
                </div>
                <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">
                        <i className="pi pi-key" />
                    </span>
                    <Password
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder={t('common:password')}
                        feedback={false}
                    />
                </div>
                <div className="p-inputgroup btn">
                    <Button
                        type="button"
                        onClick={handleSubmit}
                        label={t('common:login')}
                        disabled={loading}
                    />
                </div>
                <ResetPassword />
                <Footer />
            </div>
        </React.Fragment>
    );
}

import { gql } from '@apollo/client';

const TRACCION_ANIMAL_PROC_DIGESTIVO_CALC = gql`
  query CalcTraccionAnimalProcDigestivo(
        $tipoAnimal: String!
        $numAnimales: Int!
        $fechaInicio: String!
        $fechaFin: String!
        $empresa: ID!
  ) {
        calculoTraccionAnimalProcDigestivo(
            tipoAnimal: $tipoAnimal
            numAnimales: $numAnimales
            fechaInicio: $fechaInicio
            fechaFin: $fechaFin
            empresa: $empresa
        )
    }
`;

export { TRACCION_ANIMAL_PROC_DIGESTIVO_CALC };

////@ts-check

import React, { useContext } from "react";
import { MobXProviderContext } from "mobx-react";
import _ from "lodash";
import { Dropdown } from "primereact/dropdown";
import { useTranslation } from 'react-i18next';

import HandleLabel from "./HandleLabel";
import Error from "./Error";


export default function Select({ field, required, disabled }) {
    const idInput = '_' + Math.round(Math.random() * 10000000000);
    const { i18n, t } = useTranslation();
    const { GlobalStore: { formik } } = useContext(MobXProviderContext);
    const { fieldname, options } = field;
    const { handleChange, handleBlur, values, errors, isValid } = formik;

    const traduce = (f, b) => {
        const r = b || f;
        return (i18n.exists('domains:' + fieldname + '.'+ r))
            ? t('domains:' + fieldname + '.'+ r)
            : (i18n.exists('domains:' + field.label + '.'+ r))
                ? t('domains:' + field.label + '.'+ r)
                : r;
    };


    const handleOptions = (/** @type {any} */ options) =>
        _.map(options, ({ fieldValue, label, fieldUnit }) => { // fieldText,

            //TODO: Eliminar testeo de diccionario
            // ========================== Para TESTEO ==========================
            // @ts-ignore
            // window.sacarEntradaDiccionario(fieldname, fieldValue, fieldText, 'helpers/FieldBuilder/Select');
            // ========================== Para TESTEO ==========================

            return {
                label: traduce(fieldValue, label),  // fieldText,
                value: fieldValue,
                unit: fieldUnit
            };
        }
    );

    const itemTemplate = (/** @type {any} */ option) => {
        return  option && (<div className="templateOptionSelect">
                    {option.label}
                    {option.unit && (<small>{i18n.exists('domains:units.'+option.unit)?t('domains:units.'+option.unit):option.unit}</small>)}
                </div>) || ('<div>&nbsp;</div>');
    };



    values[fieldname] = values[fieldname] || field.defaultValue;

    return (
        <div>
            <label htmlFor={fieldname} className={`required-${required}`}>
                <HandleLabel field={field} formik={formik}></HandleLabel>
            </label>
            <Dropdown
                id={fieldname}
                inputId={idInput}
                name={fieldname}
                options={handleOptions(options)}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values[fieldname]}
                itemTemplate={itemTemplate}
                valueTemplate={itemTemplate}
                disabled={disabled}
            />
            <Error isValid={isValid} errors={errors[fieldname]} />
        </div>
    );
}

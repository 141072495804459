/* eslint-disable no-constant-condition */
// const isProd = process.env.REACT_APP_STAGE === "production";

// url = "https://us-central1-fao-bcwf-review.cloudfunctions.net";

let url;

if (!process.env.REACT_APP_STAGE) {
    url = "https://us-central1-fao-bcwf-review.cloudfunctions.net";
    // console.log('Página alojada en Servidor y conectando a: ( dev ) ', url);
} else {
    if (process.env.REACT_APP_STAGE.includes("dev")) {
        url = "https://us-central1-fao-bcwf-review.cloudfunctions.net";
    } else {
        if (process.env.REACT_APP_STAGE.includes("production")) {
        // url = "https://us-central1-fao-wbfcwf.cloudfunctions.net"; // Comentada, de momento, para que no haya accidentes
            url = "https://us-central1-fao-bcwf-review.cloudfunctions.net";
        } else { // production
            url = "http://localhost:5001/fao-bcwf-review/us-central1";
        }
    }
    // console.log('Página alojada en Local y Conectando a: (', process.env.REACT_APP_STAGE + ') ', url);
}

/*
isProd
  ? (url = "https://us-central1-fao-bcwf-review.cloudfunctions.net") // Production Endpoint
  : (url = "http://localhost:5001/fao-bcwf-review/us-central1"); // Localhost with Production Endpoint Firebase.
*/

/* isProd
  ? (url = "https://us-central1-fao-bcwf-review.cloudfunctions.net") // Development Endpoint
  : (url = "http://localhost:5000/fao-bcwf-review/us-central1"); // Localhost with Development Endpoint Firebase. */

//url = "http://localhost:5001/fao-bcwf-review/us-central1";

export default url;

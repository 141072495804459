import React from "react";
import { ProgressSpinner } from "primereact/progressspinner";

export default function GlobalLoading() {
  return (
    <div className="global-loading">
      <ProgressSpinner />
    </div>
  );
}

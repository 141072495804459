////@ts-check

import React, { useContext } from 'react';
import { MobXProviderContext } from "mobx-react";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';


import * as ROUTES from '../../../constants/routes';
import { EMPRESA_GET, EMPRESA_EDIT, EMPRESAS_LIST } from '../../../queries/queries_empresa';
import FieldBuilder from '../../../helpers/FieldBuilder';
import validationSchema from '../../../helpers/validationSchema';
import { CompLoading } from '../../Loaders';
import Err from '../../Err';

export default function EditEmpresa() {
    const { GlobalStore } = useContext(MobXProviderContext);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { id } = useParams();
    const { uid } = GlobalStore.user;

    const { loading, error, data } = useQuery(EMPRESA_GET, {
        variables: {
            id,
        },
    });

    const [
        editUser,
        { loading: mutationLoading, error: mutationError },
    ] = useMutation(EMPRESA_EDIT);

    if (GlobalStore.userType !== 1) {
        navigate(ROUTES.DASHBOARD);
    }

    if (!id) {
        return null;
    }

    if (loading) {
        return <CompLoading />;
    }

    if (error) {
        return <Err error={error} />;
    }

    //Def from GraphQL
    const def = JSON.parse(data.definicion);

    const handleSubmit = async (values) => {
        await editUser({
            variables: {
                ...values,
                uid,
            },
            refetchQueries: [
                {
                    query: EMPRESA_GET,
                    variables: {
                        id,
                    },
                },
                {
                    query: EMPRESAS_LIST,
                },
            ],
        });
        navigate(ROUTES.READ_EMPRESAS);
    };

    return (
        <React.Fragment>
            <div className="breadcrum">
                <Link to={ROUTES.READ_EMPRESAS}>
                    <i className="pi pi-list" /> &nbsp; {t('crud.registered_companies')}
                </Link>
            </div>
            <h2> {t('crud.company_update')} </h2>
            {mutationLoading && <CompLoading />}
            <Err mutationError={mutationError} />
            <Formik
                initialValues={{
                    ...data.empresa,
                    pais: data.empresa.pais.id,
                }}
                enableReinitialize={true}
                validationSchema={validationSchema(def)}
                onSubmit={handleSubmit}
            >
                {(formik) => {
                    GlobalStore.setFormik(formik);
                    return (
                        <form autoComplete="off" onSubmit={formik.handleSubmit}>
                            <div className="p-inputgroup">
                                <FieldBuilder def={def} />
                            </div>
                            <div className="btn-actions">
                                <Button
                                    type="submit"
                                    label={t('common:save')}
                                    icon="pi pi-check"
                                    disabled={mutationLoading}
                                />
                                <Button
                                    type="button"
                                    label={t('common:cancel')}
                                    onClick={() => navigate(ROUTES.READ_USERS)}
                                    disabled={mutationLoading}
                                />
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </React.Fragment>
    );
}

////@ts-check

import React, { useState, useContext } from "react";
import { MobXProviderContext } from "mobx-react";
import { TabMenu } from 'primereact/tabmenu';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';


import * as ROUTES from "../../../../constants/routes";
import PaisHuellaAguaBloque from "./paisHuellaAguaBloque";
import PaisHuellaCarbonoBloque from "./paisHuellaCarbonoBloque";

import { HandleAnioSelect, HandleMesSelect, HandleAlcanceSelect, HandleFuenteSelect } from "./ResultsFiltros";

import "./results.scss";


export default function PaisResults() {
    const { GlobalStore } = useContext(MobXProviderContext);
    const { t } = useTranslation();
    const navigate = useNavigate();
    if (GlobalStore.userType !== 1) {
        navigate(ROUTES.DASHBOARD);
    }
    const [type, setType] = useState('CARBONO'); // Para manejo de los bloques (Agua / Carbono)

    const [anio, setAnio] = useState(null);
    const [mes, setMes] = useState(null);
    const [alcance, setAlcance] = useState(null);
    const [fuente, setFuente] = useState(null);

    /**
     * Selector
     * @returns Selector Huella de agua / Huella de Carbono
     */
    const handleTypeSelect = () => {
        const tabs = ['AGUA', 'CARBONO'];
        const options = [
            { label: t('crud.water_footprint'), value: 'AGUA' },
            { label: t('crud.carbon_footprint'), value: 'CARBONO' }
        ];
        return ( <TabMenu activeIndex={tabs.indexOf(type)} onTabChange={(e) => setType(e.value.value)} model={options} /> );
    };

    /**
     * Construye los filtros
     * @returns
     */
    const showFilters = () => {

        return (
            <div className="filters">
                <h3>
                    <i className="pi pi-filter" />
                    {t('common:filters')}
                </h3>
                <div className="filters-wrapper">
                    <HandleAnioSelect anio={anio} setAnio={setAnio} />
                    <HandleMesSelect mes={mes} setMes={setMes} />
                    <HandleAlcanceSelect type={type} alcance={alcance} setAlcance={setAlcance} />
                    <HandleFuenteSelect type={type} fuente={fuente} setFuente={setFuente} />
                </div>
            </div>
        );
    };

    const showBlock = () => {
        if (!anio) { return null; }
        const datos = {
            tecnicoName: GlobalStore.user.displayName,
            paisName: GlobalStore.countryName,
            pais: GlobalStore.countryId,
            anio: anio.toString(),
            mes: mes?mes:null
        };
        switch (type) {
            case 'AGUA':
                datos.fuente = fuente || null;
                return ( <PaisHuellaAguaBloque datos={datos} /> );
            case 'CARBONO':
                datos.alcance = alcance || null;
                return ( <PaisHuellaCarbonoBloque datos={datos} /> );
            default:
                return null;
        }
    };

    //TODO: Buscar y poner en función del idioma el documento pdf
    return (
        <div className="results-container" mdl="src/components/CRUD/Read/Results/paisResults">
            <h1> {t('results.select_the_type_of_report')} </h1>
            <div className="excerpt">
                <h4> {t('results.dear_user')} </h4>
                <Trans i18nKey="results.recommendation_note">
                    a
                    <a href="http://www.fao.org/3/I8333ES/i8333es.pdf" target="_blank" rel="noopener noreferrer">
                    a
                    </a>
                    a
                </Trans>

            </div>
            {handleTypeSelect()}
            {showFilters()}
            {showBlock()}
        </div>
    );
}

import React from "react";
import _ from "lodash";
import Computed from "./Computed";

//Loaders
import { CompLoading } from "../../components/Loaders";

export default function CalcFieldBuilder({ def, calcData, calcLoading }) {
    const handleComputedFields = () => {
        const computedFields = _.filter(def, { type: "calculo" });
        if (_.isEmpty(computedFields)) {
            return null;
        }
        return (
            <div className="computed-fields">
                {_.map(computedFields, field => {
                    return (
                        <Computed field={field} data={calcData} key={field.fieldname} />
                    );
                })}
            </div>
        );
    };

    if (calcLoading) {
        return (
            <div className="loader">
                <span>Calculando...</span>
                <CompLoading />
            </div>
        );
    }

    return handleComputedFields();
}

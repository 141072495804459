//@ts-check

import React, { useContext } from 'react';
import { MobXProviderContext } from "mobx-react";
import { NavLink, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/es';
import { useQuery } from '@apollo/client';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';


import * as ROUTES from '../../../constants/routes';
import { PAISES_LIST } from '../../../queries/queries_pais';
import { CompLoading } from '../../Loaders';
import Err from '../../Err';


export default function ListPaises() {
    const { GlobalStore } = useContext(MobXProviderContext);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { loading, error, data } = useQuery(PAISES_LIST);

    if (GlobalStore.userType > 1) {
        navigate(ROUTES.DASHBOARD);
    }

    if (loading) {
        return <CompLoading />;
    }

    if (error) {
        return <Err error={error} />;
    }

    const handleTableValue = _.map(data.paises, p => {
        const handlePaisIco = (/** @type {*} */ name, /** @type {*} */ code) => {
            return (
                <div style={{ display: "flex" }}>
                    <img
                        alt={name}
                        //TODO: Arreglar enlace de banderas de paises
                        src={`https://www.countryflags.io/${code}/flat/24.png`}
                        style={{ marginRight: 5 }}
                    ></img>
                    <span>{name}</span>
                </div>
            );
        };
        return {
            nombrePais: handlePaisIco(p.nombrePais, p.codigoPais),
            fechaCreacion: moment(p.fechaCreacion).format('LL'),
            creadoPor: p.creadoPor.displayName,
            publicado: p.publicado ? 'Activo' : 'Despublicado',
            edit: (
                <NavLink to={`${ROUTES.UPDATE_PAIS}/${p.id}`}>
                    <i className="pi pi-pencil" style={{ cursor: "pointer" }}></i>
                </NavLink>
            )
        };
    });

    return (
        <React.Fragment>
            <h1> {t('crud.countries_management')} </h1>
            <NavLink to={ROUTES.CREATE_PAIS}>
                <Button
                    type="button"
                    label={t('crud.add_country')}
                    className="p-button-raised p-button-rounded"
                    icon="pi pi-plus"
                    iconPos="right"
                    style={{ marginBottom: 15 }}
                />
            </NavLink>
            <DataTable value={handleTableValue} responsiveLayout="scroll">
                <Column field="nombrePais" header={t('field.')} sortable={true} />
                <Column field="creadoPor" header={t('field.created_by')} sortable={true} />
                <Column field="fechaCreacion" header={t('field.creation_date')} sortable={true} />
                <Column field="publicado" header={t('field.status')} sortable={true} />
                <Column field="edit" header={t('field.edit')} />
            </DataTable>
        </React.Fragment>
    );
}

////@ts-check
/*jshint esversion: 8 */

import React, { useContext, useState, useEffect } from "react";
import { MobXProviderContext } from "mobx-react";
import _ from "lodash";
import { Dropdown } from "primereact/dropdown";

import HandleLabel from "./HandleLabel";
import Error from "./Error";


export default function SelectYear({field, required, disabled }) {
    const idInput = '_' + Math.round(Math.random() * 10000000000);
    const { GlobalStore: { formik } } = useContext(MobXProviderContext);
    const { fieldname, minOffset, maxOffset } = field;
    const { handleChange, handleBlur, values, errors, isValid } = formik;
    const [opts, setOpts] = useState([]);

    useEffect(() => {
        const currentYear = Number((new Date()).getFullYear());
        const range = _.rangeRight(currentYear - (minOffset || 5), currentYear + (maxOffset || 1));
        setOpts(_.map(range, (year) => {
            return {
                label: ''+year,
                value: ''+year
            };
        }));

        // values[fieldname] = values[fieldname] || ''+currentYear;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // console.log('className: ', fieldname, ' - ', values[fieldname], ' - ', values[fieldname] && values[fieldname].constructor.name);

    if (values[fieldname] && values[fieldname].constructor.name === 'Date') {
        values[fieldname] = values[fieldname].getFullYear().toString();
    }

    const getOptions = function (/** @type {any} */ opts) {
        return opts;
    };

    return (
        <div>
            <label htmlFor={fieldname} className={`required-${required}`}>
                <HandleLabel field={field} formik={formik}/>
            </label>
            <Dropdown
                id={fieldname}
                inputId={idInput}
                name={fieldname}
                options={getOptions(opts)}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values[fieldname]}
                disabled={disabled}
            />
            <Error isValid={isValid} errors={errors[fieldname]} />
        </div>
    );
}

import { gql } from '@apollo/client';

const USO_SUELO_FORESTAL_CULTIVO_CALC = gql`
	query calculoUsoSueloForestalCultivo(
		$areaCobertura: Float
		$tipoCobertura: String
		$zonaClimatica: String
	) {
		calculoUsoSueloForestalCultivo(
			areaCobertura: $areaCobertura
			tipoCobertura: $tipoCobertura
			zonaClimatica: $zonaClimatica
		)
	}
`;

export { USO_SUELO_FORESTAL_CULTIVO_CALC };
import { gql } from '@apollo/client';

const REFRIGERACION_CALC = gql`
  query CalcRefrigeracion(
    $registroRecarga: String!
    $recargaTotal: Float
    $capacidadCarga: Float
    $fugaAnual: Float
    $gasRefrigerante: String!
    $empresa: ID!
  ) {
    calculoRefrigeracion(
      registroRecarga: $registroRecarga
      recargaTotal: $recargaTotal
      capacidadCarga: $capacidadCarga
      fugaAnual: $fugaAnual
      gasRefrigerante: $gasRefrigerante
      empresa: $empresa
    )
  }
`;

export { REFRIGERACION_CALC };

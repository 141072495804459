////@ts-check

import React, { useRef, useState, useEffect } from 'react';
import _ from 'lodash';
import { DataTable } from 'primereact/datatable';
import { TreeTable } from 'primereact/treetable';
import { ColumnGroup } from 'primereact/columngroup';
import { Column } from 'primereact/column';
import { Row } from 'primereact/row';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';

import { rounder, objectRoundWithoutZeros, numberFormatTxt } from '../../../../../helpers/rounder';

import './tabla_results.scss';

export default function HuellaCarbonoResumenTabla2({ data, generatePdf, niveles}) {
    const { t, i18n } = useTranslation();
    const dt = useRef(null);

    const [exportando, setExportando] = useState(false);

    const [dataCocinados, setDataCocinados] = useState({ values: [], footer: {} });
    const [expandedKeys, setExpandedKeys] = useState({});
    const [expandedNivel, setExpandednivel] = useState([{},{},{}]);

    const { columns, values } = data;
    const roundedData = rounder(values, 10);


    const fld = ['totalCO2', 'totalCH4', 'totalN2O', 'totalHFC', 'totalCO2e', 'significancia'];

    useEffect(() => {
        // const nivel = ['alcance', 'fuenteEmision', 'producto'];
        // const nivel = ['fuenteEmision', 'alcance', 'producto'];
        const nivel = niveles.split('_');
        const oValues = [];
        const expNivel = [{}, {}, {}];
        const traduceFieldName = (nom) => {
            /*
            if (i18n.exists('domains:cambioDeDominio.' + fuet)) {
                const dtx = t('domains:cambioDeDominio.' + fuet);
                if (i18n.exists('domains:' + dtx + '.' + nom)) {
                    return t('domains:' + dtx + '.' + nom);
                }
            }
            */
            if (i18n.exists('domains:fuenteEmision.' + nom)) {
                return t('domains:fuenteEmision.' + nom);
            }
            if (i18n.exists('domains:alcance.' + nom)) {
                return t('domains:alcance.' + nom);
            }
            if (i18n.exists('domains:productoResumen.' + nom)) {
                return t('domains:productoResumen.' + nom);
            }
            console.log('- Sin Traducir (Incorporar en productoResumen) : ', nom);
            return nom;
        };
        const rellenaRegistro = (r, o, n, key) => {
            for (let i = 0; i < o.length; i++) {
                if (o[i].data.name === traduceFieldName(r[nivel[n]])) { //, r[nivel[1]])) {
                    return rellenaRegistro(r, o[i].children, n + 1, o[i].key);
                }
            }
            if (n < 2) {
                const rg = {
                    key: ((!key) ? '' : key + '-') + ('' + (o.length + 1)),
                    data: { name: traduceFieldName(r[nivel[n]]) }, //, r[nivel[1]]) },
                    children: []
                };
                o[o.length] = rg;
                expNivel[n + 1][rg.key] = true;
                rellenaRegistro(r, rg.children, n + 1, rg.key);
            } else {
                const rg = {
                    key: ((!key) ? '' : key + '-') + (o.length + 1),
                    data: { name: traduceFieldName(r[nivel[n]]) } //, r[nivel[1]]) }
                };
                rg.values = { ...rg.values, ...r };
                o[o.length] = rg;
            }
        };
        const sumaObj = (ob1, acumulado) => {
            const values = {};
            const sumaPositivos = {};
            Object.keys(ob1.values).forEach(k => {
                const v = ob1.values;
                const a = acumulado.values;
                const p = acumulado.sumaPositivos;
                if (_.isNumber(v[k])) {
                    a[k] = (a[k] === undefined) ? v[k] : (v[k] + a[k]);
                    p[k] = (p[k] === undefined) ? (v[k]>0?v[k]:0) : ((v[k]>0?v[k]:0) + p[k]);
                }
            });
            return { values: values, sumaPositivos: sumaPositivos };
        };
        const sumatorios = (s) => {
            s.values = {};
            s.sumaPositivos = {};
            const o = s.children;
            for (let i = 0; i < o.length; i++) {
                (o[i].children) && (sumatorios(o[i]));
                sumaObj(o[i], s);
            }
            return s;
        };

        const porcentages = (o, oT) => {
            for (let i = 0; i < o.length; i++) {
                o[i].porcen = o[i].porcen || {};
                Object.keys(o[i].values).forEach(k => {
                    if (_.isNumber(o[i].values[k])) {
                        if (o[i].values[k] < 0) {
                            o[i].porcen[k] = 0;
                        } else {
                            o[i].porcen[k] = numberFormatTxt((oT[k]) ? Math.ceil(100 * (100 * o[i].values[k] / oT[k])) / 100 : 0);
                        }

                    }
                });
                (o[i].children) && (porcentages(o[i].children, o[i].sumaPositivos));
            }
        };
        // traduceValores();
        if (values && values.length) {
            values.forEach(reg => rellenaRegistro(reg, oValues, 0));
            expNivel[2] = {...expNivel[1], ...expNivel[2]};
            setExpandednivel(expNivel);
            const oFooter = sumatorios({ children: oValues });
            porcentages(oValues, oFooter.sumaPositivos);
            setDataCocinados({
                values: oValues,
                footer: oFooter
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, niveles]);

    if (values && values.length === 0) {
        return (
            <div className="sinDatos">
                <span> { t('common:no_data') } </span>
            </div>
        );
    }

    const columnasExportables = (columns) => _.map(columns, (col, i) => (
        <Column key={i} field={col.field} header={t('tables.h_' + col.field) + ' (' + t('tables.h_' + col.field + '_u') + ')'} />
    ));


    const columnasVisibles = (columns) => {
        const getHeader = (col) => {
            const u = t('tables.h_' + col + '_u');
            if (u === '') {
                return t('tables.h_' + col);
            }
            return (<> {t('tables.h_' + col)} <br /><small> ({u}) </small><br /><small className="porcentage"> (%) </small> </>);
        };
        const columnFil = _.filter(columns, (col) => {
            return (col !== 'significancia');
        });
        return _.map(columnFil, (col, i) => {
            const r = getHeader(col);
            return (<Column key={i} field={col} header={r} body={templateCell} />);
        });
    };

    const footerGroup = (footer) => {
        if (footer === undefined) return null;
        const values = _.map(footer.values, (value, i) => {
            const roundedValue = numberFormatTxt(value);
            return <Column footer={roundedValue} key={i} align="center" />;
        });

        return (
            <ColumnGroup>
                <Row>
                    <Column footer={footer.label} align="center" />
                    {values}
                </Row>
            </ColumnGroup>
        );
    };

    const exportCSV = async (selectionOnly) => {
        await setExportando(true);
        dt.current.exportCSV({ selectionOnly });
    };

    // https://stackoverflow.com/questions/50147526/sheetjs-xlsx-cell-styling
    // https://github.com/ShanaMaid/sheetjs-style
    const exportExcel = () => {
        // console.log('xslx: ', values);
        import('sheetjs-style').then(xlsx => {
            const wb = xlsx.utils.book_new();
            const ws = xlsx.utils.json_to_sheet(values);
            xlsx.utils.book_append_sheet(wb, ws, 'sheet1');
            xlsx.writeFile(wb, 'test.xlsx');
        });
    };

    const expandirNivel = (n) => {
        const _expandedKeys = { ...expandedNivel[n]};
        setExpandedKeys(_expandedKeys);
    };

    const header = (
        <div className="botoneratabla2">
            <span className="export-buttons">
                <Button type="button" icon="pi pi-chevron-right" label="Nivel 1" onClick={() => expandirNivel(0)} className="mr-2" title={t('commons:expandir_1')} />
                &nbsp;
                <Button type="button" icon="pi pi-chevron-right" label="Nivel 2" onClick={() => expandirNivel(1)} className="mr-2" title={t('commons:expandir_2')} />
                &nbsp;
                <Button type="button" icon="pi pi-chevron-right" label="Nivel 3" onClick={() => expandirNivel(2)} className="mr-2" title={t('commons:expandir_3')} />
            </span>
            <span className="export-buttons">
                <Button type="button" icon="pi pi-file" onClick={() => exportCSV(false)} className="mr-2"  title={t('commons:export_csv')} />
                &nbsp;
                <Button type="button" icon="pi pi-file-excel" onClick={exportExcel} className="mr-2"  title={t('commons:export_xls')} />
                &nbsp;
                <Button type="button" icon="pi pi-file-pdf" onClick={generatePdf} className="mr-2" title={t('commons:export_pdf')} />
            </span>
        </div>
    );

    const roundTree = (o) => {
        o.forEach(v => {
            v.data = { ...v.data, ...objectRoundWithoutZeros(v.values) };
            if (v.children?.length) {
                roundTree(v.children);
            }
        });
        return o;
    };

    const templateCell = (node, column) => {
        return (<> {node.data[column.field]} <br /><small className="porcentage"> ({node.porcen[column.field]}) </small> </>);
    };

    const rowClassName = (node) => {
        if (node.children) {
            const n = node.key.split('-').length;
            return { 'rowConHijos1': (n == 1), 'rowConHijos2': (n == 2) };
        }
        return {};
    };

    const rotuloPrimeraColumna = () => {
        const n = niveles.split('_');
        return (<div className="primeraColumna">
            <span> &gt; </span><b> {t('tables.h_'+n[0])}</b> <br />
            <span className={'subtitulo1'}> &gt; </span> <small> {t('tables.h_'+n[1])} </small> <br />
            <span className={'subtitulo2'}> &gt; </span> <small> {t('tables.h_'+n[2])} </small> <br />
        </div>);
    };

    return (
        <div className="tabla_results">
            <TreeTable id="tablaResumen2" value={roundTree(dataCocinados.values)} header={header} rowClassName={rowClassName} expandedKeys={expandedKeys} onToggle={e => setExpandedKeys(e.value)} >
                <Column field="name" header={rotuloPrimeraColumna()} expander className="noWrap" />
                {columnasVisibles(fld)}
            </TreeTable>
            {exportando && (<div style={{ display: "none" }}>
                <DataTable ref={dt} value={roundedData} footerColumnGroup={footerGroup(dataCocinados.footer)}>
                    {columnasExportables(columns)}
                </DataTable>
            </div>)}
        </div>
    );
}

////@ts-check

import React, { useContext, useEffect } from 'react';
import { MobXProviderContext } from 'mobx-react';
import { InputNumber } from 'primereact/inputnumber';
import { useTranslation } from 'react-i18next';


import HandleLabel from './HandleLabel';
import Error from './Error';

import './fieldBuilder.scss';


export default function NumberInt({ field, required, disabled }) {
    const { i18n } = useTranslation();
    const { GlobalStore: { formik } } = useContext(MobXProviderContext);
    const { fieldname, step, min, max } = field;
    const { handleChange, isValid, values, touched, errors } = formik;
    const nStep = step || 1;
    useEffect(() => {
        values[fieldname] = (values[fieldname]==='' || values[fieldname]===undefined) ? null : values[fieldname]*1;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleKey = (e) => {
        var keyCode = e.keyCode ? e.keyCode : e.which ? e.which : e.charCode;
        if (keyCode == 13) {
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
    };
    return (
        <div className="elemForm">
            <label htmlFor={fieldname} className={`required-${required}`}>
                <HandleLabel field={field} formik={formik}></HandleLabel>
            </label>
            <InputNumber
                step={nStep}
                name={fieldname}
                inputId={fieldname}
                onValueChange={handleChange}
                onKeyDown={handleKey}
                value={values[fieldname]}
                showButtons
                locale={i18n.language.split('-')[0]}
                min={min}
                max={max}
                disabled={disabled}
            />
            <Error
                isValid={isValid}
                touched={touched[fieldname]}
                errors={errors[fieldname]}
            />
        </div>
    );
}

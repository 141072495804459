import React, { useState } from 'react';
import { Dropdown } from "primereact/dropdown";
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
// import _ from 'lodash';

import ChartDoughnutMultiple from './ChartDoughnutMultiple';
import colores from './colores.json';

import './graficas.scss';

export default function ChartDoughnutWrapper({ data, addChart, delChart, idChart, niveles, nivelesList }) {
    const [nivelesChart, setNivelesChart ] = useState(niveles); // setNiveles
    const idInput = '_' + Math.round(Math.random() * 10000000000);
    const { t } = useTranslation();
    const [vGraf, setVGraf] = useState({
        variable: 'totalCO2e',
        unidad: 'kg'
    });

    const getOptionsNiveles = () => {
        const nivelLabel = (v) => {
            const r = v.split('_');
            for (let i=0; i<r.length; i++) {
                r[i] = t('tables.h_'+r[i]);
            }
            return r.join(' > ');
        };
        const options = [];
        for (let i=0; i<nivelesList.length; i++) {
            options.push({
                value: nivelesList[i],
                label: nivelLabel(nivelesList[i])
            });
        }
        return options;
    };

    const options = [];
    for (let i = 1; i < data.columns.length; i++) {
        if (colores[data.columns[i].field]) {
            options.push({
                value: data.columns[i].field,
                label: t('tables.h_' + data.columns[i].field) + ' (' + t('tables.h_' + data.columns[i].field + '_u') + ') '
            });
        }
    }

    const setVariable = (v) => {
        setVGraf({
            variable: v,
            unidad: t('tables.h_' + v + '_u')
        });
    };

    const addCh = () => {
        if (addChart) {
            addChart();
        }
    };

    const delCh = () => {
        if (delChart) {
            delChart(idChart);
        }
    };

    const exportCh = () => {
        const ch = document.querySelector('#' + idChart + ' > canvas');
        if (ch && ch.getContext) {
            const link = document.createElement('a');
            link.href = ch.toDataURL("image/png").replace("image/png", "image/octet-stream");
            link.setAttribute('download', 'chart_' + idChart + '.png');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }
    };

    // https://quickchart.io/documentation/chart-js/image-export/

    // const niveles = ['alcance', 'fuenteEmision', 'producto'];

    return (
        <div className="graficaWrapperTabla2">
            <div className="graficaHeader">
                <div className="filter-input">
                    <label htmlFor={"seleccionar_variable" + idInput}> {t('chart.seleccionar_variable')} </label>
                    <Dropdown
                        name={"seleccionar_variable" + idInput}
                        inputId={idInput}
                        onChange={(v) => setVariable(v.value)}
                        value={vGraf.variable}
                        options={options}
                        placeholder={t('chart.seleccionar_variable')}
                    />
                </div>
                <div className="selectorNivel">
                    <span>
                        <Dropdown value={nivelesChart} options={getOptionsNiveles()}  onChange={(v) => setNivelesChart(v.value)} optionLabel="label" />
                    </span>
                </div>
                <div className="flex align-items-center export-buttons">
                    <Button type="button" icon="pi pi-upload" onClick={exportCh} className="mr-2" data-pr-tooltip="Exportar Gráfica" />
                    &nbsp;
                    {addChart && <Button type="button" icon="pi pi-plus-circle" onClick={addCh} className="mr-2" data-pr-tooltip="Añadir Gráfica" />}
                    {delChart && <Button type="button" icon="pi pi-minus-circle" onClick={delCh} className="mr-2" data-pr-tooltip="Borrar Gráfica" />}
                </div>
            </div>
            <ChartDoughnutMultiple data={{ ...data, variable: vGraf.variable, unidad: vGraf.unidad }} idChart={idChart} niveles={nivelesChart} />
        </div>
    );

}

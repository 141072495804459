////@ts-check

import React, { useContext } from 'react';
import { MobXProviderContext } from "mobx-react";
import { Helmet } from 'react-helmet-async';
import { useQuery } from '@apollo/client';
import { useTranslation, Trans } from 'react-i18next';


import { DASH_COUNTRY } from '../../../queries/queries_dashboard';
import { CompLoading } from '../../Loaders';
import Err from '../../Err';


export default function UserCountry() {
    const { GlobalStore } = useContext(MobXProviderContext);
    const { t } = useTranslation();
    const { loading, error, data } = useQuery(DASH_COUNTRY, {
        fetchPolicy: 'no-cache',
        variables: {
            id: GlobalStore.user.uid,
            pais: GlobalStore.countryId,
        },
    });

    if (loading) {
        return <CompLoading />;
    }

    if (error) {
        return <Err error={error} />;
    }

    const { codigoPais, nombrePais } = data.usuario.pais;

    //Todo: Vigilar las páginas de banderas de paises
    const codPaisLowCase = codigoPais.toLowerCase();
    // src={`https://cdn.ip2location.com/assets/img/flags/${codPaisLowCase}.png`}
    // src={`https://www.countryflags.io/${codigoPais}/flat/32.png`}

    return (
        <React.Fragment>
            <Helmet>
                <title>
                    {t('dashboard.usercountry_title')}
                </title>
            </Helmet>
            <h1 style={{ display: 'flex' }}>
                {t('dashboard.usercountry_type')}: {nombrePais}
                <img
                    src={`https://cdn.ip2location.com/assets/img/flags/${codPaisLowCase}.png`}
                    alt={nombrePais}
                    style={{ marginLeft: 15, height: '34px' }}
                />
            </h1>
            <div className="note-digits">
                {t('dashboard.note_digits')}
            </div>
            <ul>
                <li>
                    <Trans i18nKey="dashboard.total_comp"> </Trans> {data.empresas.length}
                </li>
                <li>
                    <Trans i18nKey="dashboard.total_users"> </Trans> {data.usuarios.length} <br />
                    <small>{t('dashboard.total_users_nota')}</small>
                </li>
            </ul>
        </React.Fragment>
    );
}

////@ts-check

import React, { useContext, useState, useEffect } from 'react';
// import ReactDOM from 'react-dom'
import { MobXProviderContext } from 'mobx-react';
import { InputNumber } from 'primereact/inputnumber';
import { convert2Liters } from '../unitConverters';
import { numberFormatTxt } from '../rounder';
import { useTranslation } from 'react-i18next';

import HandleLabel from './HandleLabel';
import Error from './Error';
import { t } from 'i18next';


// https://dmitripavlutin.com/react-context-and-usecontext/

export default function Number({ field, required, disabled }) {
    const { i18n } = useTranslation();
    const { GlobalStore: { formik } } = useContext(MobXProviderContext);
    const { fieldname, step, convert, min, max } = field;
    const { handleChange, isValid, values, touched, errors, setFieldValue } = formik;

    const [cValue, setCValue] = useState(null);
    const [vSource, setVSource] = useState(false);
    const [cSelect, setCSelect] = useState(values[convert.selector]);
    const [cValueU, setCValueU] = useState(null);

    useEffect(() => {
        setFieldValue(convert.target, (cValueU !== null) ? cValueU : cValue, false);
        values[fieldname] = (values[fieldname]==='' || values[fieldname]===undefined) ? null : values[fieldname]*1;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cValueU]);

    const getConversion = () => {
        const visible = !!document.getElementById(convert.selector);
        const cVis = (vSource !== visible);
        const cVal = (cValue !== values[fieldname]);
        const cSel = (cSelect !== values[convert.selector]);
        cVis && setVSource(visible);
        cVal && setCValue(values[fieldname]);
        cSel && setCSelect(values[convert.selector]);
        let v = null;
        if (visible) {
            if (cVis || cSel || cVal) {
                v = convert2Liters(values[convert.selector], values[fieldname]);
                setCValueU(v);
                // setCValueU((v !== null)?v:values[fieldname]);
            }
        } else {
            if (values[fieldname] !== cValueU) {
                setCValueU(values[fieldname]);
            }
        }

        // console.log('( visible => cSelect: cValue = cValueU = v) ' + visible + ' => ' + cSelect + ': ' + cValue + ' = ' + cValueU + ' = ' + v);
        return (visible && (cValueU !== null) && (values[convert.selector] !== 'litros'))
            ? (<div className="unidadConvertida"> {numberFormatTxt(cValueU)} {t('field.liters')} </div>)
            : '';
    };

    let mf = 2;
    if (step) {
        const tn = (step - step.toFixed()).toString().split('-');
        mf = (tn.length>1) ? 1*tn[1] : tn[0].length - 2;
    }
    mf = (mf < 0) ? 0 : mf;

    const handleKey = (e) => {
        var keyCode = e.keyCode ? e.keyCode : e.which ? e.which : e.charCode;
        if (keyCode == 13) {
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
    };

    return (
        <div className="elemForm">
            <label htmlFor={fieldname} className={`required-${required}`}>
                <HandleLabel field={field} formik={ formik } />
            </label>
            <InputNumber
                step={step}
                name={fieldname}
                inputId={fieldname}
                onValueChange={handleChange}
                onKeyDown={handleKey}
                mode="decimal"
                value={values[fieldname]}
                minFractionDigits={mf}
                maxFractionDigits={mf}
                showButtons
                locale={i18n.language.split('-')[0]}
                min={min}
                max={max}
                disabled={disabled}
            />
            <Error
                isValid={isValid}
                touched={touched[fieldname]}
                errors={errors[fieldname]}
            />
            {getConversion()}
        </div>
    );
}

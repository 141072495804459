import React, { useContext, useState, useRef, useEffect } from "react";
import { MobXProviderContext } from "mobx-react";
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { useTranslation } from 'react-i18next';


import HandleLabel from "./HandleLabel";
import Error from "./Error";

import "./fieldBuilder.scss";

const maxSize = 1048576;

export default function TextUpload({ field, required, disabled }) {
    const { t } = useTranslation();
    const { GlobalStore: { formik } } = useContext(MobXProviderContext);
    const { fieldname } = field;
    const { handleChange, handleBlur, values, errors, isValid, setFieldValue, setFieldError } = formik;
    const refInputFile = useRef(null);
    const [vdata, setVdata] = useState({
        leyendo: false, // Para gestionar el momento en que se lee el fichero desde local a la web (no al servidor)
        fileOrig: null, // Copia de la url del fichero para descargar
        textOrig: null, // Copia del texto original
        warning: null
    });

    useEffect(() => {
        setVdata({
            textOrig: values[fieldname],
            fileOrig: values['fileData']
        });
        setFieldValue('fileData', null, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const defWarning = (nameFile) => {
        let v = { ...vdata, warning: null, leyendo: false };
        if (nameFile) { // Cambio por seleccionar un fichero para subir
            (vdata.fileOrig !== '') && (v = { ...v, warning: 'file_warn_update_file' });
        } else { // Cambio por modificar el contenido del textbox
            setFieldValue('fileData', null, false);
            (vdata.fileOrig !== '') && (v = { ...v, warning: 'file_warn_delete_file' });
        }
        setVdata(v);
    };

    const invoiceUploadHandler = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > maxSize) {
                setFieldError(fieldname, t('error:file_to_big'));
                setVdata({ warn: null });
                return;
            }
            const fileReader = new FileReader();
            fileReader.onload = (e) => {
                setFieldValue('fileData', e.target.result, false);
                setFieldValue(fieldname, file.name, false);
                defWarning(file.name);
            };
            setVdata({ leyendo: true });
            fileReader.readAsDataURL(file); //onClick={invoiceUploadHandler}
        }
    };

    const invoiceDownloadHandler = () => {
        if (vdata.fileOrig) {
            fetch(vdata.fileOrig, { method: 'GET' })
                .then((response) => response.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', vdata.textOrig);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                });
        }
    };

    const changeText = (event) => {
        handleChange(event);
        defWarning();
    };

    const handleKey = (e) => {
        var keyCode = e.keyCode ? e.keyCode : e.which ? e.which : e.charCode;
        if (keyCode == 13) {
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
    };

    return (
        <div>
            <label htmlFor={fieldname} className={`required-${required}`}>
                <HandleLabel field={field} formik={formik}></HandleLabel>
            </label>
            <div className="p-inputgroup">
                {vdata.fileOrig && (<Button type="button"
                    icon="pi pi-download"
                    className="botonIzq"
                    onClick={invoiceDownloadHandler}
                    title={t('common:download_file', { v: vdata.textOrig })}
                />)}
                <InputText
                    type="text"
                    name={fieldname}
                    id={fieldname}
                    disabled={disabled}
                    onChange={changeText}
                    onBlur={handleBlur}
                    onKeyDown={handleKey}
                    value={values[fieldname]}
                />
                <Button
                    type="button"
                    icon="pi pi-upload"
                    className="botonDer"
                    onClick={() => refInputFile.current.click()}
                    title={t('common:select_to_upload')}
                />
                <input
                    type="file"
                    id="myfile"
                    style={{ display: "none" }}
                    ref={refInputFile}
                    onChange={invoiceUploadHandler}
                />
                {vdata.leyendo && (<i className="tempLeyendo pi pi-spin pi-spinner" style={{ 'fontSize': '2em' }}></i>)}
            </div>
            <Error isValid={isValid} errors={errors[fieldname]} />
            {vdata.warning && (
                <div className="err-msg">
                    <Message severity="info" text={t('error:' + vdata.warning, { v: vdata.textOrig })}></Message>
                </div>
            )}
        </div>
    );
}

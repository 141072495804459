import { gql } from '@apollo/client';

const DASH_COUNTRY = gql`
  query UserGetCountry($id: ID!, $pais: ID) {
    usuario(id: $id) {
      pais {
        id
        nombrePais
        codigoPais
      }
    }
    usuarios(pais: $pais) {
      id
    }
    empresas(pais: $pais) {
      id
    }
  }
`;

const DASH_COMP = gql`
  query DashboardCompany($empresa: ID!, $uid: ID!, $pais: ID) {
    empresa(id: $empresa) {
      nombreEmpresa
    }
    usuarios(empresa: $empresa, pais: $pais) {
      id
    }
    fincas(uid: $uid) {
      id
    }
    configuraciones(uid: $uid) {
      anio
    }
  }
`;

export { DASH_COMP, DASH_COUNTRY };

//@ts-check

import React, { useContext, useState } from 'react';
import { MobXProviderContext } from "mobx-react";
import { NavLink, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';


import { numberFormatTxt } from '../../../helpers/rounder';
import MapGoogleGetPoints from '../../../helpers/mapGoogle/MapGoogleGetPoints';
import * as ROUTES from '../../../constants/routes';
import { FINCAS_LIST } from '../../../queries/queries_finca';
import { CompLoading } from '../../Loaders';
import Err from '../../Err';


export default function ListFincas() {
    const { GlobalStore } = useContext(MobXProviderContext);
    const [viewMap, setViewMap] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { loading, error, data } = useQuery(FINCAS_LIST, {
        variables: {
            uid: GlobalStore.user.uid
        }
    });

    if (GlobalStore.userType !== 2) {
        navigate(ROUTES.DASHBOARD);
    }

    if (loading) {
        return <CompLoading />;
    }

    if (error) {
        return <Err error={error} />;
    }

    const handleTableValue = _.map(data.fincas, f => {
        const c = (f.coordinates && f.coordinates != '') ? JSON.parse(f.coordinates) : {};
        return {
            ...f,
            coords: c,
            coordsTxt: (c.lat === undefined) ? ' - ' : `( ${numberFormatTxt(c.lat, 6)} , ${numberFormatTxt(c.lng, 6)} )`,
            publicado: f.publicado ? 'Activo' : 'Despublicado',
            edit: (
                <NavLink to={`${ROUTES.UPDATE_FINCA}/${f.id}`}>
                    <i className="pi pi-pencil" style={{ cursor: "pointer" }}></i>
                </NavLink>
            )
        };
    });

    // console.log(handleTableValue);
    const handleClickShowMap = () => {
        setViewMap(!viewMap);
    };

    const exportToGeoJson = () => {
        const f = data.fincas;
        const fea = [];
        for (let i = 0; i < f.length; i++) {
            const c = (f[i].coordinates && f[i].coordinates != '') ? JSON.parse(f[i].coordinates) : {};
            if (c.lat) {
                fea.push({
                    type: "Feature",
                    properties: { finca: f[i].nombreFinca },
                    geometry: {
                        type: 'Point',
                        coordinates: [c.lng, c.lat]
                    }
                });
            }
        }
        const tg = {
            type: 'FeatureCollection',
            features: fea
        };
        download(JSON.stringify(tg, null, 0), 'fincas.json', "text/plain");
    };

    function download(content, fileName, contentType) {
        const a = document.createElement("a");
        const file = new Blob([content], { type: contentType });
        a.href = URL.createObjectURL(file);
        a.download = fileName;
        a.click();
    }

    return (
        <React.Fragment>
            <h1> {t('crud.management_of_farms')} </h1>
            <NavLink to={ROUTES.CREATE_FINCA}>
                <Button
                    type="button"
                    label={t('crud.add_farm')}
                    className="p-button-raised p-button-rounded"
                    icon="pi pi-plus"
                    iconPos="right"
                    style={{ marginBottom: 15 }}
                />
            </NavLink>
            <Button
                type="button"
                label={t('crud.show_farms')}
                className="p-button-raised p-button-rounded"
                icon="pi pi-map"
                iconPos="right"
                onClick={handleClickShowMap}
                style={{ marginBottom: 15, marginLeft: 15 }}
            />
            {viewMap && (<Button
                type="button"
                label={t('crud.download_geoJSON')}
                className="p-button-raised p-button-rounded"
                icon="pi pi-download"
                iconPos="right"
                onClick={exportToGeoJson}
                style={{ marginBottom: 15, marginLeft: 15 }}
            />)}
            {viewMap && (<MapGoogleGetPoints points={handleTableValue} />)}
            <DataTable value={handleTableValue} responsiveLayout="scroll">
                <Column
                    field="nombreFinca"
                    header={t('field.farm')}
                    sortable={true}
                    filter={true}
                    filterMatchMode="contains"
                />
                <Column field="coordsTxt" header={t('field.coordinates')} align="center" />
                <Column field="edit" header={t('field.edit')} />
            </DataTable>
        </React.Fragment>
    );
}

// //@ts-check

import React, { useContext } from 'react';
import _ from 'lodash';
import { observer, MobXProviderContext } from 'mobx-react';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';
import { Helmet } from 'react-helmet-async';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { Formik } from 'formik';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


import { FORM_ADD, FORM_LIST } from '../../../../queries/queries_forms';
import * as ROUTES from '../../../../constants/routes';
import { CompLoading } from '../../../Loaders';
import mapParamsWithForm from '../../mapParams';
import FincaSelectorGlobal from '../../../Dashboard/UserOp/FincaSelectorGlobal';
import Err from '../../../Err';

import FieldBuilder from '../../../../helpers/FieldBuilder';
import CalcFieldBuilder from '../../../../helpers/CalcFieldBuilder';
import validationSchema from '../../../../helpers/validationSchema';
import initialValues from '../../../../helpers/initialValues';


function AddForm() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const params = useParams();
    const { form } = params;

    const { GlobalStore } = useContext(MobXProviderContext);

    const formData = mapParamsWithForm(form, GlobalStore);

    const [
        addForm,
        { loading: mutationLoading, error: mutationError },
    ] = useMutation(FORM_ADD);

    const {
        Ico,
        QUERY_DEF,
        QUERY_VARIABLES,
        QUERY_CALC,
        QUERY_RETURN,
    } = formData;

    const NAME = t('menu.' + formData.NAME);

    const { loading, error, data } = useQuery(QUERY_DEF, {
        variables: QUERY_VARIABLES
    });

    const [
        getCalc,
        { loading: calcLoading, data: calcData, error: calcError },
    ] = useLazyQuery(QUERY_CALC, { fetchPolicy: 'no-cache' });

    if (GlobalStore.userType !== 3) {
        navigate(ROUTES.DASHBOARD);
    }

    if (loading) {
        return <CompLoading />;
    }

    if (error) {
        return <Err error={error} />;
    }

    const def = data && JSON.parse(data.definicion);

    const {
        user: { uid },
        fincaId,
    } = GlobalStore;

    if (_.isEmpty(fincaId)) {
        return (
            <div>
                <h2> {t('crud.please_select_a_Farm')} </h2>
                <p> {t('crud.associate_it_with_a_farm')} </p>
                <FincaSelectorGlobal />
            </div>
        );
    }

    const handleValues = (values) => {
        const omitEmptyValues = _.reduce(
            values,
            (acc, value, key) => {
                if (value === '') {
                    return acc;
                }
                return {
                    ...acc,
                    [key]: value,
                };
            },
            {}
        );
        const o = { ...omitEmptyValues };
        if (o.fecha) {
            o.fecha = moment(o.fecha).format('YYYY-MM');
        }
        if (o.fechaInicio) {
            o.fechaInicio = moment(o.fechaInicio).format('YYYY-MM-DD');
        }
        if (o.fechaFin) {
            o.fechaFin = moment(o.fechaFin).format('YYYY-MM-DD');
        }
        return o;
    };

    const handleCalc = (values) => {
        return getCalc({
            variables: {
                ...handleValues(values),
                empresa: GlobalStore.compId,
                finca: fincaId.value
            },
        });
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>
                    {t('crud.register_of_name', { NAME: NAME })}
                </title>
            </Helmet>

            <Formik
                initialValues={initialValues(def)}
                validationSchema={validationSchema(def)}
                enableReinitialize={true}
                validateOnBlur={false}
                onSubmit={async (values) => {
                    await addForm({
                        variables: {
                            uid,
                            tipoFormulario: form,
                            finca: fincaId.value,
                            camposFormulario: JSON.stringify(handleValues(values)),
                        },
                        refetchQueries: [
                            {
                                query: FORM_LIST,
                                variables: {
                                    uid: GlobalStore.user.uid,
                                    tipoFormulario: form,
                                },
                            },
                        ],
                    });
                    navigate(`${ROUTES.READ_FORMS}/${form}`);
                }}
            >
                {(formik) => {
                    GlobalStore.setFormik(formik);
                    return (
                        <form autoComplete="off" onSubmit={formik.handleSubmit}>
                            <div className="breadcrum">
                                <Link to={`/list/forms/${form}`}>
                                    <i className="pi pi-list" /> &nbsp; {t('crud.view_saved_records')}
                                </Link>
                            </div>
                            <div className="form-container">
                                <div>
                                    <h2> {t('crud.register_of')} {NAME}</h2>
                                    <FieldBuilder def={def} />
                                </div>
                                <div className="calc-fields">
                                    <div className="calc-container">
                                        <h3>
                                            {t('crud.calculation_of')} {NAME} <Ico />
                                        </h3>
                                        {mutationLoading && (
                                            <div className="loader">
                                                <span> {t('common:saving')} </span>
                                                <CompLoading />
                                            </div>
                                        )}
                                        <Err calcError={calcError} mutationError={mutationError} error={null} />
                                        <CalcFieldBuilder
                                            def={def}
                                            calcData={
                                                calcData ? JSON.parse(calcData[QUERY_RETURN]) : null
                                            }
                                            calcLoading={calcLoading}
                                        />
                                        <div className="buttons">
                                            <Button
                                                type="button"
                                                label={t('common:calculate')}
                                                iconPos="right"
                                                className="p-button-raised p-button-rounded"
                                                disabled={!formik.isValid}
                                                onClick={async (e) => {
                                                    e.preventDefault();
                                                    const validate = await formik.validateForm();
                                                    if (!_.isEmpty(validate)) return null;
                                                    return handleCalc(formik.values);
                                                }}
                                            />
                                            <Button
                                                disabled={!formik.isValid}
                                                type="submit"
                                                className="p-button-raised p-button-rounded"
                                                icon="pi pi-check"
                                                iconPos="right"
                                                label={t('common:save')}
                                            />
                                        </div>
                                        <div className="note">
                                            <i className="pi pi-info" />
                                            {t('crud.note_calculations')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    );
                }}
            </Formik>
        </React.Fragment>
    );
}
export default observer(AddForm);

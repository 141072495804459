//@ts-check
import React, { useContext } from 'react';
import { MobXProviderContext } from "mobx-react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';

import * as ROUTES from '../../constants/routes';
import Header from '../Header';
import Sidebar from '../Sidebar';
import Dashboard from '../Dashboard';
import { AddUser, AddPais, AddConfig, AddConfigClone, AddEmpresa, AddFinca, AddForm } from '../CRUD/Create';
import { ListUsers, ListPaises, ListEmpresas, ListFincas, ListForms, ListConfig, ListConfigGlobal, ListResults, PaisResults } from '../CRUD/Read';
import { EditUser, EditEmpresa, EditPais, EditFinca, EditConfig, EditForm } from '../CRUD/Update';
import DeleteForm from '../CRUD/Delete/Form';
import DuplicateForm from '../CRUD/Duplicate/Form';
import { GlobalLoading } from '../Loaders';

const USER_TYPE = gql`
  query UserGetType($id: ID!) {
    usuario(id: $id) {
      type
      pais {
        id
        nombrePais
      }
      empresa {
        id
        nombreEmpresa
      }
    }
  }
`;

function Logged() {
    const { GlobalStore } = useContext(MobXProviderContext);
    const { user, sidebar, setUserType, setCountryId, setCompId, setCompName, setCountryName} = GlobalStore;

    const { loading, error, data } = useQuery(USER_TYPE, {
        variables: {
            id: user.uid
        },
    });

    if (loading) {
        return <GlobalLoading />;
    }

    if (error) {
        // should update Global Store with Err
        return null;
    }

    const { type, pais, empresa } = data.usuario;

    setUserType(type);
    setCountryId(pais.id);
    setCountryName(pais.nombrePais);
    setCompId(empresa.id);
    setCompName(empresa.nombreEmpresa);

    return (
        <BrowserRouter>
            <div className={sidebar ? 'main-wrapper ' : 'main-wrapper sidebar-off'}>
                <Header />
                <Sidebar />
                <div className='content-wrapper'>
                    <Routes>
                        <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />

                        {/*  ----------------- CREATE  ----------------- */}
                        <Route path={ROUTES.CREATE_USER} element={<AddUser />} />
                        <Route path={ROUTES.CREATE_PAIS} element={<AddPais />} />
                        <Route path={ROUTES.CREATE_CONFIG} element={<AddConfig />} />
                        <Route path={`${ROUTES.CREATE_CONFIG_CLONE}/:anio/:id`} element={<AddConfigClone />} />
                        <Route path={ROUTES.CREATE_EMPRESA} element={<AddEmpresa />} />
                        <Route path={ROUTES.CREATE_FINCA} element={<AddFinca />} />
                        <Route path={`${ROUTES.CREATE_FORM}/:form`} element={<AddForm />} />

                        {/* ----------------- READ  ----------------- */}
                        <Route path={ROUTES.READ_USERS} element={<ListUsers />} />
                        <Route path={ROUTES.READ_PAISES} element={<ListPaises />} />
                        <Route path={ROUTES.READ_EMPRESAS} element={<ListEmpresas />} />
                        <Route path={ROUTES.READ_FINCAS} element={<ListFincas />} />
                        <Route path={`${ROUTES.READ_FORMS}/:form`} element={<ListForms />} />
                        <Route path={ROUTES.READ_CONFIG} element={<ListConfig />} />
                        <Route path={ROUTES.READ_CONFIG_GLOBAL} element={<ListConfigGlobal />} />
                        <Route path={ROUTES.READ_RESULTS} element={<ListResults />} />

                        <Route path={ROUTES.READ_COUNTRY_RESULTS} element={<PaisResults />} />

                        {/*  ----------------- UPDATE  ----------------- */}
                        <Route path={`${ROUTES.UPDATE_USER}/:id`} element={<EditUser />} />
                        <Route path={`${ROUTES.UPDATE_PAIS}/:id`} element={<EditPais />} />
                        <Route path={`${ROUTES.UPDATE_EMPRESA}/:id`} element={<EditEmpresa />} />
                        <Route path={`${ROUTES.UPDATE_FINCA}/:id`} element={<EditFinca />} />
                        <Route path={`${ROUTES.UPDATE_FORM}/:form/:id`} element={<EditForm />} />
                        <Route path={`${ROUTES.UPDATE_CONFIG}/:anio/:id`} element={<EditConfig />} />
                        <Route path={`${ROUTES.UPDATE_CONFIG}/:id`} element={<EditConfig />} />

                        {/*  ----------------- DELETE  ----------------- */}
                        <Route path={`${ROUTES.DELETE_FORM}/:form/:id`} element={<DeleteForm />} />

                        {/*  ----------------- DUPLICATE  ----------------- */}
                        <Route path={`${ROUTES.DUPLICATE_FORM}/:form/:id`} element={<DuplicateForm />} />
                    </Routes>
                </div>
            </div>
        </BrowserRouter>
    );
}

// <Route path={ROUTES.READ_COUNTRY_RESULTS} element={<CountryAdminCsv />} />

export default Logged;

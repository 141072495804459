//@ts-check

import React, { useContext } from 'react';
import { MobXProviderContext } from 'mobx-react';
import * as ROUTES from '../../constants/routes';
import { NavLink } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import {
    FaHome,
    FaUserEdit,
    FaUsers,
    FaGlobeAmericas,
    FaListAlt,
} from 'react-icons/fa';

export default function AdminMenu() {
    const { GlobalStore } = useContext(MobXProviderContext);

    const { t } = useTranslation();

    return (
        <ul>
            <li>
                <NavLink title={t('common:home')} to={ROUTES.DASHBOARD}>
                    <FaHome />
                    <span>{t('common:home')}</span>
                </NavLink>
            </li>
            <li>
                <NavLink title={t('common:edit_profile')} to={`${ROUTES.UPDATE_USER}/${GlobalStore.user.uid}`}>
                    <FaUserEdit />
                    <span>{t('common:edit_profile')}</span>
                </NavLink>
            </li>
            <li>
                <NavLink title={t('menu.users_list')} to={ROUTES.READ_USERS}>
                    <FaUsers />
                    <span>{t('menu.users_list')}</span>
                </NavLink>
            </li>
            <li>
                <NavLink title={t('menu.country_list')} to={ROUTES.READ_PAISES}>
                    <FaListAlt />
                    <span>{t('menu.country_list')}</span>
                </NavLink>
            </li>
            <li>
                <NavLink title={t('menu.create_country')} to={ROUTES.CREATE_PAIS}>
                    <FaGlobeAmericas />
                    <span>{t('menu.create_country')}</span>
                </NavLink>
            </li>
        </ul>
    );
}

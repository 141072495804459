import React, { useState } from "react";

import { useTranslation, Trans } from 'react-i18next';

import { Button } from "primereact/button";

//Modal
import Reconocimientos from "./Reconocimientos";

export default function Footer() {
    const [modalOpen, setModalOpen] = useState(false);

    const { t } = useTranslation();

    return (
        <footer>
            <div>
                <h3>{t('login.world_forum')}</h3>
                <div>  <Trans i18nKey="login.secretariat"> </Trans> </div>
                <div className="address">
                    <a
                        href="https://www.google.com/maps/search/Viale+delle+Terme+di+Caracalla+%0D%0A+00153+Roma,+Italia?entry=gmail&amp;source=g"
                        target="_blank"
                        rel="noopener noreferrer">
                        Viale delle Terme di Caracalla <br />
                        00153 Roma, Italia
                    </a>
                </div>
                <div>
                    <a href="mailto:WBF@fao.org" target="_blank" rel="noopener noreferrer">
                        WBF@fao.org
                    </a>
                    &nbsp;|&nbsp;
                    <a href="http://www.fao.org/wbf" target="_blank" rel="noopener noreferrer">
                        www.fao.org/wbf
                    </a>
                    &nbsp;| @FAOwbf
                </div>
                <div className="reconocimientos-modal">
                    <Button
                        type="button"
                        label={t('common:about')}
                        onClick={() => setModalOpen(true)}></Button>
                    <Reconocimientos
                        modalIsOpen={modalOpen}
                        closeModal={() => setModalOpen(false)}
                    />
                </div>
            </div>
            <div className="bottom">
                {t('login.more_information')}{" "}
                <strong>
                    <a
                        href="https://firebasestorage.googleapis.com/v0/b/fao-wbfcwf.appspot.com/o/docs%2Fterms.pdf?alt=media&token=36b6e26e-341f-4e94-b5d8-d04d6832b8e6"
                        rel="noopener noreferrer"
                        target="_blank">
                        {t('login.terms')}
                    </a>
                </strong>
                , {t('login.contact')}
                <a href="mailto:web@fao.org" rel="noopener noreferrer" target="_blank">
                    web@fao.org
                </a>
                {t('login.or_with_the_email')}
                <a href="mailto:wbf@fao.org" rel="noopener noreferrer" target="_blank">
                    wbf@fao.org
                </a>
                .
            </div>
        </footer>
    );
}

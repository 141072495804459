////@ts-check

import _ from 'lodash';

function handleDep(dep) {
    const depFieldsList = _.reduce(
        dep,
        (acc, { depFields }) => {
            return [...acc, ...depFields];
        },
        []
    );
    return _.reduce(
        depFieldsList,
        (acc, { fieldname, defaultValue }) => {
            return {
                ...acc,
                [fieldname]: defaultValue || ''
            };
        },
        {}
    );
}

const getDefaultValue = (/** @type {any} */ defVal) => {
    return (defVal
    ? ((defVal === '_currentYear') ? ''+(new Date()).getFullYear() : defVal)
    : '');
};

export default function initialValues(def) {
    //console.log('- initialValues: ', def);
    return _.reduce(
        def,
        (acc, { type, fieldname, defaultValue, dependents = null }) => {
            const skip = type === 'calculo'; // type === 'hidden' ||
            if (skip) {
                return acc;
            }
            if (type === 'conditional') {
                return {
                    ...acc,
                    [fieldname]: getDefaultValue(defaultValue),
                    ...handleDep(dependents)
                };
            }
            return {
                ...acc,
                [fieldname]: getDefaultValue(defaultValue)
            };
        },
        {}
    );
}

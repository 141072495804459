////@ts-check

import React from 'react';
import _ from 'lodash';
import { Message } from 'primereact/message';
import { useTranslation } from 'react-i18next';

export default function Error({ isValid, touched=false, errors }) {
    const { t } = useTranslation();
    const show = !isValid && (!_.isEmpty(errors) || touched);
    if (show) {
        let er = errors;
        let v;
        if (errors) {
            // console.log('errors', errors);
            if (errors.startsWith("field_min") || errors.startsWith("field_max")) {
                const t = errors.split(':');
                v = {v: errors.startsWith("field_min") ? t[1]-1 : t[1] };
                er = t[0];
            }
            if (errors.indexOf('must be a `number` type, but') != -1) {
                er = 'field_required';
            }
            if (errors.indexOf('must be a `string` type, but') != -1) {
                er = 'field_required';
            }
            return (
                <div className="err-msg">
                    <Message severity="warn" text={t('error:'+er, v)}></Message>
                </div>
            );
        }
    }
    return null;
}

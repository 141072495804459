////@ts-check

import React, { useState, useContext } from 'react';
import { MobXProviderContext } from "mobx-react";
import { InputNumber } from 'primereact/inputnumber';
import { useTranslation } from 'react-i18next';

import MapGoogleSetPoint from '../mapGoogle/MapGoogleSetPoint';
import Error from "./Error";


export default function MapGooglePoint({ field, required, disabled }) {
    const idInput = '_' + Math.round(Math.random() * 10000000000);
    const { t, i18n } = useTranslation();

    // ---- Configuración de componente ----
    const { GlobalStore: { formik } } = useContext(MobXProviderContext);
    const { fieldname } = field;
    const { handleChange, values, errors, isValid } = formik;

    // ---- configuración del mapa ----
    const coord = values[fieldname] && JSON.parse(values[fieldname]);
    const ilat = coord?.lat || null;
    const ilng = coord?.lng || null;
    const [lat, setLat] = useState(ilat);
    const [lng, setLng] = useState(ilng);

    let optionsMap = undefined;

    const changePointMap = (/** @type {{ lat: any; lng: any; }} */ pnt) => {
        handleChange( {target: {id: fieldname, name: fieldname, value: JSON.stringify(pnt) }} );
    };

    const changePointFromMap = (/** @type {{ lat: any; lng: any; }} */ pnt) => {
        if (!disabled) {
            setLat(pnt.lat);
            setLng(pnt.lng);
            changePointMap(pnt);
        }
    };

    const changeLat = (/** @type {{ value: any; }} */ e) => {
        setLat(e.value);
        changePointMap({lat: e.value, lng: lng});
    };

    const changeLon = (/** @type {{ value: any; }} */ e) => {
        setLng(e.value);
        changePointMap({lat: lat, lng: e.value});
    };

    return (
        <div>
            <div>
                <label htmlFor={fieldname} className={`required-${required}`}>
                    {t('common:location')}
                </label>
            </div>
            <MapGoogleSetPoint
                optMap={optionsMap}
                lat={lat}
                lng={lng}
                onChange={changePointFromMap}
                language={i18n.language.split('-')[0]}
            />
            <InputNumber
                step={0.000001}
                id={idInput+'_lat'}
                onChange={changeLat}
                mode="decimal"
                value={lat}
                locale="en-US"
                disabled={disabled}
            />
            &nbsp;
            <InputNumber
                step={0.000001}
                id={idInput+'_lon'}
                onChange={changeLon}
                mode="decimal"
                value={lng}
                locale="en-US"
                disabled={disabled}
            />
            <Error isValid={isValid} errors={errors[fieldname]} />
        </div>
    );
}

// //@ts-check

import React, { Component } from 'react';
import { GMap } from 'primereact/gmap';

import { loadGoogleMaps, removeGoogleMaps } from './LoadGoogleMaps';
/* global google */

export default class MapGoogleGetPoints extends Component {

    /**
     * Constructor
     * @param {*} props { optMap, lat, lng, name, onChange, language }
     * @returns
     */
    constructor(props) {
        super(props);

        const c = {};
        const p = props.points;
        for (let i = 0; i < p.length; i++) {
            if (p[i].coords.lat !== undefined) {
                c.lat = (p[i].coords.lat + (c.lat || p[i].coords.lat)) / 2;
                c.lng = (p[i].coords.lng + (c.lng || p[i].coords.lng)) / 2;
            }
        }

        this.state = {
            center: c,
            googleMapsReady: false,
            selectedPosition: null,
            overlays: null
        };

        this.onMapReady = this.onMapReady.bind(this);
    }

    componentDidMount() {
        loadGoogleMaps(this.props.language, () => {
            this.setState({ googleMapsReady: true });
        });
    }

    componentWillUnmount() {
        removeGoogleMaps();
    }

    onMapReady(r) {
        const pts = this.props.points;
        const puntos = [];
        const bounds = new google.maps.LatLngBounds();
        for (let i = 0; i < pts.length; i++) {
            const pt = this.createMark(pts[i].coords.lat, pts[i].coords.lng, pts[i].nombreFinca);
            if (pt) {
                puntos.push(pt);
                bounds.extend(new google.maps.LatLng(pts[i].coords.lat, pts[i].coords.lng));
            }
        }
        this.setState({ overlays: puntos });
        r.map.fitBounds(bounds);
    }

    createMark(latitud, longitud, name) {
        const lat = (Number(latitud) === latitud) ? latitud : 0;
        const lng = (Number(longitud) === longitud) ? longitud : 0;
        return ((lat === 0) && (lng === 0)) ? undefined :
            new google.maps.Marker({position: { lat: lat, lng: lng }, title: name || ''});
    }

    render() {
        const optionsMap = this.props.optMap || {
            center: {lat: this.state.center.lat || 0, lng: this.state.center.lng || 0},
            zoom: 3
        };
        return (
            <div>
                {
                    this.state.googleMapsReady && (
                        <div style={{ marginBottom: 2 }}>
                            <GMap
                                overlays={this.state.overlays}
                                options={optionsMap}
                                style={{ width: '100%', minHeight: '320px' }}
                                onMapReady={this.onMapReady}
                            />
                        </div>
                    )
                }
            </div>
        );
    }
}

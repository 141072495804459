//@ts-nocheck
"use strict";


import React, { useContext } from 'react';
import { MobXProviderContext } from "mobx-react";
import _ from 'lodash';
import { Dropdown } from 'primereact/dropdown';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { CompLoading } from '../../Loaders';
import Err from '../../Err';

const COUNTRY_GET = gql`
  query PaisesGet {
    paises {
      id
      nombrePais
    }
  }
`;


export default function PaisSelector() {
    const { GlobalStore: { formik } } = useContext(MobXProviderContext);
    const { t } = useTranslation();
    const idInput = '_' + Math.round(Math.random() * 10000000000);
    const { loading, error, data } = useQuery(COUNTRY_GET);

    if (loading) {
        return <CompLoading />;
    }

    if (error) {
        return <Err error={error} />;
    }

    const options = _.map(data.paises, ({ id, nombrePais }) => {
        return {
            label: nombrePais,
            value: id
        };
    });

    const { handleChange, handleBlur, values } = formik;

    const fieldname = 'pais';

    return (
        <Dropdown
            name={fieldname}
            id={fieldname}
            inputId={idInput}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values[fieldname]}
            options={options}
            placeholder={t('common:country')}
        />
    );
}

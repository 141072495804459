////@ts-check

import React from 'react';
import { Chart } from 'primereact/chart';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { singleRounder, numberFormatTxt} from '../../../../../helpers/rounder';

import './graficas.scss';
import colores from './coloresChart2.json';

/**
 * @param {*} param0
 *  data: {
 *      title: string       // Titulo de la gráfica
 *      variable: string    // nombre de la variable a representar
 *      values: [numbers]   // Array de valores
 *  }
 */
export default function ChartDoughnutMultiple({ data, idChart, niveles }) {
    let contColor = 1;
    const { t, i18n } = useTranslation();
    const dG = [];
    _.map(data.values, (el) => {
        dG.push((el[data.variable] && el[data.variable]>0)?el[data.variable]*1:0);
    });
    let totalValue = dG.reduce((ac, el) => ac + el, 0);
    const totalAbs = totalValue;
    // let totalValue = data.values.reduce((ac, el) => ac + (1 * (el[data.variable] || 0)), 0);
    // const totalAbs = data.values.reduce((ac, el) => ac + (1 * (Math.abs((el[data.variable] || 0)))), 0);
    const labelTitle = t('tables.h_' + data.variable);
    const nivels = niveles.split('_');
    // const ind = Object.fromEntries(nivels.map((k,i) => [k, i]));
    // const i0 = ind['alcance'];
    // const i1 = ind['fuenteEmision'];
    // const i2 = ind['producto'];

    // ============================================================================================================
    // ===== Tratamiento de datos =========================================================
    // ============================================================================================================

    /*
    const randomHSL = (v) => {
        if (v && v==='pepe') {
            const r = v.split('#');
            if (r.length === 1) {
                return 'hsla(' + colores[r[0]].h + ',' + colores[r[0]].s + '%,' + colores[r[0]].l + '%,1)';
            }
            if (r.length === 2) {
                return 'hsla(' + colores[r[0]].h + ',' + (colores[r[0]].s - 10) + '%,' + (colores[r[1]] || 80) + '%,1)';
            }
            if (r.length === 3) {
                return 'hsla(' + colores[r[0]].h + ',' + (colores[r[2]] || 40 + (Math.round(Math.random() * 55))) + '%,' + (colores[r[1]] || 40 + (Math.round(Math.random() * 55))) + '%,1)';
            }
        }
        const c = (36 * Math.random()) + (36 * contColor);
        contColor = (contColor + 3) % 10;
        return 'hsla(' + ~~(c) + ',' + '70%,' + '80%,1)';
    };
    */

    const calculaColor = (el) => {
        if (el.hsl) {
            const q = el.hsl;
            return {
                nivel: (el.nivel || 0) + 2,
                h: q.h+el.n*3,
                s: (el.nivel===1)? q.s : (q.s-el.n*2>90)?90:q.s-el.n*2,
                l: (el.nivel===2)? q.l : (q.l+el.n*2>90)?90:q.l+el.n*2
            };
        }
        const c = (36 * Math.random()) + (36 * contColor);
        contColor = (contColor + 3) % 10;
        return {
            h: c,
            s: 60,
            l: 70
        };
    };

    const convColor = (el) => {
        return 'hsla(' + ~~(el.hsl.h) + ',' + el.hsl.s + '%,' + el.hsl.l+'%,1)';
    };

    /**
     * Función especifica para datos con alcance, fuenteEmision y producto
     * @param {*} reg un registro de datos en bruto, talcual entra en el componente
     */
    const traduceEntrada = (reg, etq) => {
        const tx = {};
        tx.fuenteEmision = (i18n.exists('domains:fuenteEmision.' + reg.fuenteEmision)) ? t('domains:fuenteEmision.' + reg.fuenteEmision) : reg.fuenteEmision;
        if (reg.alcance) {
            tx.alcance = (i18n.exists('domains:alcance.' + reg.alcance)) ? t('domains:alcance.' + reg.alcance) : reg.alcance;
        }
        if (reg.producto) {
            /*
            if (i18n.exists('domains:cambioDeDominio.' + reg.fuenteEmision)) {
                const dtx = t('domains:cambioDeDominio.' + reg.fuenteEmision);
                if (i18n.exists('domains:' + dtx + '.' + reg.producto)) {
                    tx.producto = t('domains:' + dtx + '.' + reg.producto);
                }
            }
            */
            tx.producto = (i18n.exists('domains:productoResumen.' + reg.producto)) ? t('domains:productoResumen.' + reg.producto) : reg.producto;
        }
        if (reg.tipoProduccion) {
            tx.tipoProduccion = (i18n.exists('domains:tipoProduccion.' + reg.tipoProduccion)) ? t('domains:tipoProduccion.' + reg.tipoProduccion) : reg.tipoProduccion;
        }
        if (reg.claseFinca) {
            tx.claseFinca = reg.claseFinca;
        }
        const trad = [];
        const etqA = etq.split('#');
        for (let i=0; i<etqA.length; i++) {
            trad.push(tx[nivels[i]]);
        }
        return trad;
    };

    const dNivel = [];
    const configEtq = {};

    const configurarValues = () => {
        const col = {};
        const colN1 = {n:0};
        const setColor = (etq) => {
            const b = etq.split('#');
            const b0 = b[0];
            if (b.length ===1) {
                col[b0] = col[b0] || { hsl : colores[etq] || calculaColor(colN1), n: 0 };
                return col[b0];
            }
            const b1 = [b0,b[1]].join('#');
            if (b.length ===2) {
                col[b0].n++;
                col[b1] = col[b1] || { hsl : colores[etq] || calculaColor(col[b0]), n: 0 };
                return col[b1];
            }
            if (b.length ===3) {
                col[b1].n++;
                col[etq] = col[etq] || { hsl : colores[etq] || calculaColor(col[b1]) };
                return col[etq];
            }
            return {h:0,s:0,l:0};
        };
        // Extracción y ordenación de datos (IMPORTANTE: la ordenación es imprescindible porque la relación entre niveles es por posición)
        const arrayDatos = (_.map(data.values, (el) => {
            const oEtq = {};
            const r = nivels.map(a => { oEtq[a]=el[a]; return el[a]; }).join('#');
            const v = (el[data.variable] && el[data.variable]>0)?el[data.variable]*1:0;
            return [r, v, oEtq];
        })).sort((a,b) => (a[0]<b[0])?-1:1);
        // Configurar Datos
        _.map(arrayDatos, (el) => {
            const etqt = el[0].split('#');
            let etq = '';
            nivels.forEach((n,i) => {
                dNivel[n] = dNivel[n] || {};
                etq = etq + etqt[i];
                dNivel[n][etq] = (dNivel[n][etq] || 0) + el[1];
                configEtq[etq] = {
                    color: convColor(setColor(etq)),
                    traducido: traduceEntrada(el[2], etq)
                };
                etq = etq + '#';
            });
        });
    };

    configurarValues();

    // ============================================================================================================
    // ===== Funciones de definición de ls grafica ================================================================
    // ============================================================================================================

    const cutout = ['25%', '30%', '40%'];
    const getDataSets = () => {
        const dataSets = [];
        nivels.forEach((n, i) => {
            dataSets.push({
                label: labelTitle,
                data: _.map(dNivel[n], (el) => singleRounder(el)),
                labels: Object.keys(dNivel[n]),
                etiq: Object.keys(dNivel[n]),
                backgroundColor: _.map(Object.keys(dNivel[n]), (v) => configEtq[v].color),
                cutout: cutout[i],  // Porcentaje de corte interno (la inversa del grosor)
            });
        });
        return dataSets;
    };

    const chartData = {
        // labels: Object.keys(dNivel[nivels[nivels.length - 1]]),
        // labels: [Object.keys(dNivel[nivels[0]]), Object.keys(dNivel[nivels[1]]), Object.keys(dNivel[nivels[2]])],
        labels: Object.keys(dNivel[nivels[0]]),
        datasets: getDataSets().reverse()
    };

    const lightOptions = {
        maintainAspectRatio: true,
        aspectRatio: 1.7,
        plugins: {
            legend: {
                position: 'left',
                align: 'start',
                title: {
                    display: true,
                    text: [t('chart.' + data.title), labelTitle + '   ' + totalAbs.toFixed(1) + ' ' + data.unidad],
                    font: { weight: 'bold' },
                    padding: 20
                },
                onClick: (evt, legendItem, legend) => {
                    const meta2= legend.chart.getDatasetMeta(2);
                    meta2.data[legendItem.index].hidden = !meta2.data[legendItem.index].hidden;
                    const signo = (meta2.data[legendItem.index].hidden) ? -1 : 1;
                    totalValue = totalValue + (legend.chart.data.datasets[2].data[legendItem.index] * signo);
                    for (let j=chartData.datasets.length-2; j>-1; j--) {
                        const meta = legend.chart.getDatasetMeta(j);
                        for (let i=0; i<chartData.datasets[j].etiq.length; i++) {
                            if (chartData.datasets[j].etiq[i].indexOf(legendItem.label) !== -1) {
                                meta.data[i].hidden = !meta.data[i].hidden;
                            }
                        }
                    }
                    legend.chart.update();
                },
                onHover: (evt, item, legend) => {
                    const chart = legend.chart;
                    const tooltip = chart.tooltip;
                    const chartArea = chart.chartArea;
                    tooltip.setActiveElements([{
                        datasetIndex: 2,
                        index: item.index,
                    }], {
                        x: (chartArea.left + chartArea.right) / 2,
                        y: (chartArea.top + chartArea.bottom) / 2,
                    });
                    chart.update();
                },
                labels: {
                    generateLabels(chart) {
                        const dt = chart.data;
                        // Object.keys(dNivel[nivels[nivels.length-1]])
                        if (dt.labels.length && dt.datasets.length) {
                            const { labels: { pointStyle } } = chart.legend.options;
                            const meta = chart.getDatasetMeta(2);
                            return dt.labels.map((label, i) => {
                                const style = meta.controller.getStyle(i);
                                const value = numberFormatTxt(meta._parsed[i]);
                                return {
                                    text: configEtq[label].traducido[0] + '   ( ' + value + ' ) ',
                                    fillStyle: style.backgroundColor,
                                    strokeStyle: style.borderColor,
                                    lineWidth: style.borderWidth,
                                    pointStyle: pointStyle,
                                    hidden: meta.data[i].hidden,
                                    label: label,
                                    index: i // Dato necesario para "toogling" items
                                };
                            });
                        }
                        return [];
                    }
                }
            },
            datalabels: {
                display: 'auto',
                padding: 2,
                font: {
                    size: 12,
                    color: 'blue'
                },
                formatter: (value, ctx) => {
                    const dtIndex = ctx.datasetIndex;
                    const dt = ctx.dataIndex;
                    if (ctx.chart._metasets[dtIndex].data[dt].hidden) return '';
                    // const sum = ctx.chart._metasets[0].total;
                    const percentage = Math.round((value * 100 / totalValue) * 10) / 10;
                    if (percentage === 0 || Number.isNaN(percentage) || !Number.isFinite(percentage)) return '';
                    return percentage.toFixed(1) + '%';
                }
            },
            tooltip: {
                callbacks: {
                    label: (ctx) => {
                        // No utilizar "ctx.label" es erroneo, se calcula mal (a partir del labels general).
                        const lbl = Object.keys(dNivel[nivels[nivels.length - 1 - ctx.datasetIndex]])[ctx.dataIndex];
                        const r = configEtq[lbl].traducido.slice(0);
                        const percentage = Math.round((ctx.parsed * 100 / totalValue) * 10) / 10;
                        const pg = (percentage === 0 || Number.isNaN(percentage) || !Number.isFinite(percentage)) ? ''
                            : '( '+ percentage.toFixed(1) + '% )';
                        r.push(`${ctx.formattedValue} ${pg}`);
                        return r;
                    }
                }
            }
        }
    };

    const TextoEnCentro = {
        id: 'TextoEnCentro',
        afterDraw(chart) {
            const { ctx, chartArea: { left, top, width, height } } = chart;
            ctx.save();
            ctx.textAlign = 'center';
            ctx.font = 'bolder 14px Arial';
            const tot = (totalValue).toFixed(1) + ' ' + data.unidad;
            ctx.fillText(tot, (width / 2) + left, (height / 2) + top);
            ctx.font = 'bolder 16px Arial';
            ctx.fillStyle = 'blue';
            const percentge = (chart._metasets[0].total * 100 / totalAbs).toFixed(1) + "%";
            ctx.fillText(percentge, (width / 2) + left, (height / 2) + top + 20);
        }
    };

    return (
        <div className="grafica flex justify-content-center">
            <Chart type="doughnut" data={chartData} options={lightOptions} plugins={[ChartDataLabels, TextoEnCentro]} id={idChart} />
        </div>
    );
}

// https://www.npmjs.com/package/hw-chartjs-plugin-colorschemes
// https://www.npmjs.com/package/chartjs-color
// https://www.npmjs.com/package/chartjs-plugin-doughnutlabel-v3
// https://www.npmjs.com/package/chartjs-plugin-gradient
// https://www.npmjs.com/package/chartjs-plugin-autocolors
// https://www.npmjs.com/package/chartjs-plugin-labels


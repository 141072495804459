//@ts-check

import React from 'react';
import * as ROUTES from '../../constants/routes';
import { NavLink } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import FincaSelectorGlobal from '../Dashboard/UserOp/FincaSelectorGlobal';

import {
  FaLightbulb,
  FaIndustry,
  FaTractor,
  FaWrench,
  FaSnowflake,
  FaSeedling,
  FaTrashAlt,
  FaTint,
  FaStickerMule
} from 'react-icons/fa';

import {
    GiTurd,
    GiForest,
    GiCoalPile
} from "react-icons/gi";


export default function OpMenuDashboard() {

    const { t } = useTranslation();

  return (
    <React.Fragment>
      <h3>{t('menu.select_farm')}</h3>
      <FincaSelectorGlobal />
      <h4>{t('menu.register_type')}</h4>
      <ul>
        <li>
          <NavLink to={`${ROUTES.CREATE_FORM}/electricidad`}>
            <FaLightbulb />
            <span>{t('menu.electricity')}</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={`${ROUTES.CREATE_FORM}/combustion-estacionaria`}>
            <FaIndustry />
            <span>{t('menu.stationary_combustion')}</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={`${ROUTES.CREATE_FORM}/combustion-movil`}>
            <FaTractor />
            <span>{t('menu.mobile_combustion')}</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={`${ROUTES.CREATE_FORM}/mantenimiento`}>
            <FaWrench />
            <span>{t('menu.maintenance_products')}</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={`${ROUTES.CREATE_FORM}/refrigeracion`}>
            <FaSnowflake />
            <span>{t('menu.refrigeration')}</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={`${ROUTES.CREATE_FORM}/fertilizantes`}>
            <FaSeedling />
            <span>{t('menu.fertilizers')}</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={`${ROUTES.CREATE_FORM}/residuos`}>
            <FaTrashAlt />
            <span>{t('menu.waste')}</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={`${ROUTES.CREATE_FORM}/huella-agua`}>
            <FaTint />
            <span>{t('menu.water_footprint')}</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={`${ROUTES.CREATE_FORM}/traccion-animal-proc-digestivo`}>
            <FaStickerMule />
            <span>{t('menu.traccion_animal_proc_digestivo')}</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={`${ROUTES.CREATE_FORM}/traccion-animal-estiercol`}>
            <GiTurd />
            <span>{t('menu.traccion_animal_estiercol')}</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={`${ROUTES.CREATE_FORM}/uso-suelo-forestal-forestal`}>
            <GiForest />
            <span>{t('menu.uso_suelo_forestal_forestal')}</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={`${ROUTES.CREATE_FORM}/uso-suelo-cultivo-forestal`}>
            <GiForest />
            <span>{t('menu.uso_suelo_cultivo_forestal')}</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={`${ROUTES.CREATE_FORM}/uso-suelo-forestal-cultivo`}>
            <GiForest />
            <span>{t('menu.uso_suelo_forestal_cultivo')}</span>
          </NavLink>
        </li>
        <li>
          <NavLink to={`${ROUTES.CREATE_FORM}/uso-suelo-carbono`}>
            <GiCoalPile />
            <span>{t('menu.uso_suelo_carbono')}</span>
          </NavLink>
        </li>
      </ul>
    </React.Fragment>
  );
}

import React from "react";
import { ProgressSpinner } from "primereact/progressspinner";

export default function CompLoading() {
  return (
    <ProgressSpinner
      style={{ width: "2em", height: "2em" }}
      strokeWidth="8"
      fill="#EEEEEE"
      animationDuration=".5s"
    />
  );
}

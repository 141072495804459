////@ts-check

import React, { useState, useContext } from 'react';
import { MobXProviderContext } from "mobx-react";
import { NavLink } from 'react-router-dom';
import _ from 'lodash';
import { useQuery, useMutation } from '@apollo/client';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


import * as ROUTES from '../../../constants/routes';
import { USERS_LIST, USER_ENABLE, USER_DISABLE } from '../../../queries/queries_user';
import { useHandleUserVariables } from '../hooks';
import { CompLoading } from '../../Loaders';
import Err from '../../Err';


export default function ListUsers() {
    const { GlobalStore } = useContext(MobXProviderContext);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const variables = useHandleUserVariables();

    const { loading: dataLoading, error, data, refetch } = useQuery(USERS_LIST, {
        fetchPolicy: 'no-cache',
        variables,
    });

    const [enableUser, { error: enableError }] = useMutation(USER_ENABLE);
    const [disableUser, { error: disError }] = useMutation(USER_DISABLE);

    if (GlobalStore.userType > 2) {
        navigate(ROUTES.DASHBOARD);
    }

    if (dataLoading || loading) {
        return <CompLoading />;
    }

    if (error) {
        return <Err error={error} enableError={enableError} disError={disError} />;
    }

    const handleAction = async (action, id) => {
        setLoading(true);
        const variables = {
            id: id,
            uid: GlobalStore.user.uid,
        };
        switch (action) {
            case 'enable':
                await enableUser({
                    variables,
                });
                await refetch();
                break;
            case 'disable':
                await disableUser({
                    variables,
                });
                await refetch();
                break;
            default:
                return null;
        }
        setLoading(false);
    };

    //TODO: Traducir: ¿dominio?
    const handleType = (type) => {
        switch (type) {
            case 0:
                return t('crud.fao_administrator'); // 'Administrador FAO'
            case 1:
                return t('crud.country_admin'); // 'Admins. de País';
            case 2:
                return t('crud.company_admin'); // 'Admins. de Empresa';
            case 3:
                return t('crud.farm_admin'); // 'Admins. de Finca';
            default:
                return null;
        }
    };

    const handleTableValue = _.map(data.usuarios, (u) => {
        return {
            ...u,
            disabled: u.disabled ? (
                <span style={{ color: "brown" }}> {t('common:disabled')} </span>
            ) : (
                <span style={{ color: "green" }}> {t('common:enabled')} </span>
            ),
            action: u.disabled ? (
                <Button
                    type="button"
                    label={t('common:enable')}
                    className="p-button-secondary p-button-rounded"
                    icon="pi pi-check"
                    onClick={() => handleAction('enable', u.id)}
                />
            ) : (
                <Button
                    type="button"
                    label={t('common:disable')}
                    className="p-button-secondary p-button-rounded"
                    icon="pi pi-minus"
                    onClick={() => handleAction('disable', u.id)}
                />
            ),
            edit: (
                <NavLink to={`${ROUTES.UPDATE_USER}/${u.id}`}>
                    <i className="pi pi-pencil" style={{ cursor: "pointer" }}></i>
                </NavLink>
            ),
            type: handleType(u.type),
            pais: u.pais.nombrePais,
        };
    });

    return (
        <React.Fragment>
            <h1> {t('crud.user_management')} </h1>
            <NavLink to={ROUTES.CREATE_USER}>
                <Button
                    type="button"
                    label={t('crud.add_user')}
                    className="p-button-raised p-button-rounded"
                    icon="pi pi-plus"
                    iconPos="right"
                    style={{ marginBottom: 15 }}
                />
            </NavLink>
            <DataTable value={handleTableValue} responsiveLayout="scroll">
                <Column field="displayName" header={t('field.name')} sortable={true} filter={true} filterMatchMode="contains" />
                <Column field="email" header={t('field.email')} sortable={true} />
                <Column field="pais" header={t('field.country')} sortable={true} />
                <Column field="type" header={t('field.type')} sortable={true} />
                <Column field="disabled" header={t('field.status')} sortable={true} />
                <Column field="action" header={t('field.action')} />
                <Column field="edit" header={t('field.edit')} />
            </DataTable>
        </React.Fragment>
    );
}

const config = {
  apiKey: "AIzaSyCQ2Z2-cVUgn0YnI-f5cliYcyXYcuCJbsw",
  authDomain: "fao-bcwf-review.firebaseapp.com",
  databaseURL: "https://fao-bcwf-review.firebaseio.com",
  projectId: "fao-bcwf-review",
  storageBucket: "fao-bcwf-review.appspot.com",
  messagingSenderId: "751786038518",
  appId: "1:751786038518:web:53393e7b5723f7bf1788d7",
  measurementId: "G-MYFCWVM1B5",
};

export default config;
